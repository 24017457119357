import TableGrid from "../components/TableGrid";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "../config";
import {Link, useParams} from "react-router-dom";
import {GridColumnIcon} from "@glideapps/glide-data-grid";
import {Button, Col, Form, Menu, Modal, Row, Skeleton, Spin} from "antd";
import {ColumnEditForm} from "../utils/Forms";
import EditColumnPopup from "../features/EditColumnPopup";
import {
    postRowForTableRequest,
    putCellValueTableRequest,
    putColumnOrderRequest, useApi
} from "../utils/requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {LoadingOutlined} from "@ant-design/icons";
import {useLoader} from "../utils/LoaderContext";


function TableView(props) {
    const {getDataTypeListRequest, getDataListRequest, getTableColumnListRequest} = useApi();
    let {tableId} = useParams();

    const [dataList, setDataList] = useState([]);
    const [columnList, setColumnList] = useState([]);

    const [isBackgroundLoading, setIsBackgroundLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataTypeList, setDataTypeList] = useState([]);
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [columnMetaData, setColumnMetaData] = useState({});

    const { addRequest, removeRequest } = useLoader();

    useEffect(() => {
        updateTableMetaData();
    }, [tableId])

    const onUpdate = () => {
        updateTableMetaData();
    }

    const updateTableMetaData = async () => {
        console.log("updateTableMetaData = ", tableId);
        setIsLoading(true);
        await getColumnList();
        await getDataTypes();
        await getDataList();
        setIsLoading(false);
    }


    const getDataList = async () => {
        const {isOk, responseData} = await getDataListRequest(tableId, null);
        setDataList(responseData);
    }

    const getColumnList = async () => {
        const dataResponse = await getTableColumnListRequest(tableId);

        const serverColumnList = dataResponse["column_list"];
        let columnList = [];
        for (let i = 0; i < serverColumnList.length; i++) {
            let columnName = serverColumnList[i]["name"];
            if (columnName == null) {
                columnName = "Untitled";
            }

            let icon = GridColumnIcon.HeaderString;
            if (serverColumnList[i]["type"] == "NUMBER") {
                icon = GridColumnIcon.HeaderNumber;
            }

            columnList.push({
                id: serverColumnList[i]["id"],
                title: columnName,
                hasMenu: true,
                icon: icon,
                f_type: serverColumnList[i]["f_type"],
                width: serverColumnList[i]["width"],
                type: serverColumnList[i]["type"],
                settings: serverColumnList[i]["settings"],
                is_readonly: serverColumnList[i]['is_read_only']
            })
        }
        setColumnList(columnList);
    }

    const getDataTypes = async () => {
        const columnTypeList = await getDataTypeListRequest();
        setDataTypeList(columnTypeList);
    }

    const onColumnDelete = async (columnId) => {
        addRequest(tableId);
        const requestUrl = `${ENV_API_HOST}/column/${columnId}`;
        const response = await fetch(requestUrl, {
            method: "DELETE", headers: {
                'Content-Type': 'application/json', // Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        await onUpdate();
        removeRequest(tableId);
    }

    const handleColumnEdit = (columnData) => {
        setColumnMetaData(columnData);
        setIsOpenEditModal(true);
    }

    const handleColumnMoved = async (columnId, newOrder) => {
        // setIsBackgroundLoading(true);
        addRequest(tableId);
        await putColumnOrderRequest(columnId, newOrder);
        removeRequest(tableId);
        // setIsBackgroundLoading(false);
    }

    if (isLoading) {
        return (<div style={{height: "100vh", backgroundColor: "#fff"}}>
            <div style={{marginTop: "15px", marginLeft: "15px", paddingRight: "15px"}}>
                <Skeleton active={true} />
            </div>
        </div>);
    }

    const handleColumnEditCancel = () => {
        setColumnMetaData({});
        setIsOpenEditModal(false);
    }

    const handleColumnEditSuccess = async () => {
        setColumnMetaData({});
        setIsOpenEditModal(false);
        await onUpdate();
    }


    const handleNewRow = async (tableId, rowFrontendId) => {
        // setIsBackgroundLoading(true);
        addRequest(tableId);
        await postRowForTableRequest(tableId, rowFrontendId);
        removeRequest(tableId);
        // setIsBackgroundLoading(false);
    }

    const handleCellValueChanged = async (columnId, rowId, content) => {
        // setIsBackgroundLoading(true);
        addRequest(tableId);
        await putCellValueTableRequest(tableId, rowId, columnId, content);
        removeRequest(tableId);
        // setIsBackgroundLoading(false);
    }

    // const handleSelectRowListChanged = (selectRowList) => {
    //     setSelectedRowList(selectRowList);
    // }





    return (<div>

        <EditColumnPopup
            tableId={tableId}
            isOpenEditModal={isOpenEditModal}
            columnMetaData={columnMetaData}
            onSuccess={handleColumnEditSuccess}
            onCancel={handleColumnEditCancel}
        />
        <TableGrid
            tableId={tableId}
            dataList={dataList}
            columnList={columnList}
            typeList={dataTypeList}
            onUpdate={onUpdate}
            onColumnDelete={onColumnDelete}
            onColumnEdit={handleColumnEdit}
            onColumnMoved={handleColumnMoved}
            onRowNew={handleNewRow}
            onCellValueChanged={handleCellValueChanged}
            // onRowSelect={handleSelectRowListChanged}
        />
    </div>)
}

export default TableView;

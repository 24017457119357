import {Button, Col, Divider, Form, Input, Row, Select, Skeleton, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "../utils/requests";
import PluginSettingsView from "../features/PluginSettingsView";
import usePublicApi from "../utils/publicRequests";
import {useUser} from "../utils/UserProvider";


function UserProfileView(props) {
    const {getLanguageListRequest} = usePublicApi();
    const {updateProfileRequest} = useApi();
    const { user , updateUser } = useUser();

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(true);
    const [isFormSaving, setIsFormSaving] = useState(false);

    const [languageList, setLanguageList] = useState([]);
    const [formInitValues, setFormInitValues] = useState({});

    const initLoading = async () => {
        const {isOk, apiResponse} = await getLanguageListRequest();

        console.log("languageList = ", apiResponse);
        let languageOptionList = [];
        for (let i = 0; i < apiResponse.length; i++) {
            languageOptionList.push({
                "value": apiResponse[i]["id"].toString(),
                "label": apiResponse[i]["name"],
            })
        }
        setLanguageList(languageOptionList);

        const initValues = {
            "language": user["preference"]["language"]["id"].toString(),
            "theme": user["preference"]["theme"],
        }

        setFormInitValues(initValues);

        setIsLoading(false);
    }

    useEffect( () => {
        console.log("useEffect : user = ", user);
        initLoading();
    }, []);


    const onFinish = async (values) => {
        setIsFormSaving(true);
        const {isOk, apiResponse} = await updateProfileRequest(values);
        updateUser(apiResponse);
        setIsFormSaving(false);
    }


    if(isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>);
    }
    
    return(<div style={{background: "#fff", paddingLeft: "20px", paddingRight: "20px"}}>
        <Row>
            <Col span={24}>
                <h2>Profile</h2>
                <Divider />

                <Row>
                    <Col span={12}>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                            initialValues={formInitValues}
                        >
                            <Form.Item name="language" label={"Language:"}>
                                <Select options={languageList} />
                            </Form.Item>


                            <Form.Item name="theme" label={"Theme:"}>
                                <Select options={[
                                    {
                                        "value": "default",
                                        "label": "Light",
                                    },
                                    {
                                        "value": "dark",
                                        "label": "Dark",
                                        disabled: true
                                    }
                                ]} />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isFormSaving}>
                                    Update profile
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>




            </Col>
        </Row>
    </div>)
}


export default UserProfileView;

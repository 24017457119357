import InterfaceFormPreview from "../components/InterfaceForm";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {putCellValueTableRequest, useApi} from "../utils/requests";
import {Skeleton} from "antd";
import {debounce, debouncedOnEdit} from "../utils/InterfaceUtils";

function InterfacePublicView(props) {
    let {interfaceSlug} = useParams();

    const {getSelectedInterfacePublicRequest, postInterfaceVersionCreateNewRowRequest, getDataListRequest} = useApi();

    const [isLoading, setIsLoading] = useState(true);


    const [interfaceData, setInterfaceData] = useState(null);
    const [fieldsFormData, setFieldsFormData] = useState({});
    const [settingFormData, setSettingFormData] = useState({});
    const [workflowsFormData, setWorkflowsFormData] = useState([]);

    const [updateId, setUpdateId] = useState(null);
    const [frontendRowId, setFrontendRowId] = useState(null);

    const [selectedTabKey, setSelectedTabKey] = useState("fields");

    const [rowData, setRowData] = useState(null);
    const [rawRowData, setRawRowData] = useState(null);

    useEffect(() => {
        getSelectedInterface();
    }, [interfaceSlug]);


    const getRowData = async (tableId, frontendRowId) => {
        const {isOk, responseData} = await getDataListRequest(tableId, frontendRowId);
        return responseData[0]
    }

    const getSelectedInterface = async () => {
        setIsLoading(true);

        const {isOk, responseData} = await getSelectedInterfacePublicRequest(interfaceSlug);
        setFieldsFormData(responseData["version"]["value_map"]);
        setInterfaceData(responseData);

        const versionId = responseData["version"]["id"];
        let currentFrontendRowId = responseData["version"]["empty_frontend_row_id"];
        if (currentFrontendRowId == null) {
            const {responseStatus, responseData } = await postInterfaceVersionCreateNewRowRequest(versionId);
            setFrontendRowId(responseData["frontend_row_id"]);
            currentFrontendRowId = responseData["frontend_row_id"];
        } else {
            setFrontendRowId(currentFrontendRowId);
        }

        const tableId = responseData["account_meta_table"]["id"]

        const responseRowDataMap = await getRowData(tableId, currentFrontendRowId);

        let rowDataMap = {}
        let rowDataKeyList = Object.keys(responseRowDataMap);

        for (let i = 0; i < rowDataKeyList.length; i++) {
            const columnKey = `column:${rowDataKeyList[i]}`
            rowDataMap[columnKey] = responseRowDataMap[rowDataKeyList[i]]["d"];
        }

        setRowData(rowDataMap);
        setRawRowData(responseRowDataMap);

        let apiSettingsFormData = responseData["version"]["config"];

        if (responseData["version"]["workflows"].length > 0) {
            const selectedWorkflow = responseData["version"]["workflows"][0];
            apiSettingsFormData["workflow_button_text"] = selectedWorkflow["field_name"];
            apiSettingsFormData["workflow_connected_button"] = selectedWorkflow["account_workflow_id"].toString();
        }

        setWorkflowsFormData(responseData["version"]["workflows"]);
        setSettingFormData(apiSettingsFormData);
        setIsLoading(false);
    }


    const onFormFileChanges = (columnId, fileData) => {
        console.log("[InterfaceView] onFormFileChanges = ", fileData);
        const cellValue = {
            "d": fileData.filename,
            "m": {
                "ldg": false,
                "dsbl": null,
                "d": {
                    "path": fileData.path,
                    "public_path": fileData.public_path,
                    "size": fileData.size,
                }
            }
        }

        const tableId = interfaceData["account_meta_table"]["id"];

    }

    const onFormValueChanges = (value) => {
        console.log("[InterfaceView] onFormValueChanges = ", value);
        const tableId = interfaceData["account_meta_table"]["id"];
        debouncedOnEdit(tableId, frontendRowId, value);
    }

    if (isLoading || interfaceData == null) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>)
    }


    // <div style={{
    //         backgroundColor: "#f6f6f6",
    //         minHeight: "calc(100vh - 62.71px)",

    return(<div style={{overflow: 'auto', height: '100vh', backgroundColor: "#f6f6f6"}}>
        <InterfaceFormPreview
            tableId={interfaceData["account_meta_table"]["id"]}
            frontendRowId={frontendRowId}
            updateId={updateId}
            interfaceId={interfaceData["id"]}
            interfaceData={interfaceData}
            settingFormData={fieldsFormData}
            formSettingFormData={settingFormData}
            workflowsFormData={workflowsFormData}
            rowData={rowData}
            rawRowData={rawRowData}
            onValuesUpdate={onFormValueChanges}
            onFileUpdate={onFormFileChanges}
        />

    </div>)

}

export default InterfacePublicView;

import {Col, Divider, Form, Row, Spin, Input, Checkbox, Button, Card} from "antd";
import {useEffect, useState} from "react";
import "../unauth.css";
import usePublicApi from "../utils/publicRequests";
import {Link, useNavigate} from "react-router-dom";
import {faEnvelopeCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ForgotPasswordView(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {postResetPassword} = usePublicApi();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleRequest  = async (postData) => {
        setIsLoading(true);
        const {statusCode, data} = await postResetPassword(postData);
        const isOk = statusCode >= 200 && statusCode < 300;

        if (isOk) {
            setIsSuccess(true);
        }

        setIsLoading(false);
    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);

        handleRequest(values);

    };

    // <FontAwesomeIcon icon={faEnvelopeCircleCheck} />

    if (isSuccess) {
        return (<div style={{
            width: '100%',
            background: "#f5f5f5",
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Card title="Password recovery" style={{width: "400px"}}>
                <Row>
                    <Col span={24} style={{textAlign: "center"}}>
                        <FontAwesomeIcon style={{"color": "rgb(30, 48, 80)"}} size={"6x"} icon={faEnvelopeCircleCheck} />
                        <div style={{marginTop:"10px"}}>Password recovery instructions have been sent to your email.</div>
                    </Col>
                </Row>

            </Card>

        </div>);
    }



    return (
        <div style={{
            width: '100%',
            background: "#f5f5f5",
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Card title="Password recovery" style={{width: "400px"}}>

                <Form
                    form={form}
                    name="login"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input size={"large"} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Reset password
                        </Button>
                    </Form.Item>
                </Form>

                <Row>
                    <Col span={24}><Link to={"/signup"}>Signup</Link> or <Link to={"/signin"}>Sign in</Link></Col>
                </Row>
            </Card>

        </div>
    );
}


export default ForgotPasswordView;

import {Button, Card, Col, Divider, Row, Skeleton, Spin, Tag} from "antd";
import {useEffect, useState} from "react";
import { useApi} from "../utils/requests";

function PluginListItem(props) {
    const {postInstallPluginRequest} = useApi();
    const {pluginItem} = props;

    const [isPluginInstalling, setIsPluginInstalling] = useState(false);

    const handleInstallPlugin = async (pluginId) => {
        setIsPluginInstalling(true);
        await postInstallPluginRequest(pluginId);
        props.onUpdate();
        setIsPluginInstalling(false);
    }

    return (<Card key={pluginItem["id"]} style={{marginBottom: "10px"}}>
        <Row>
            <Col span={22}><h3 style={{margin: 0, padding: 0}}>{pluginItem["name"]}</h3> <br /> <Tag>{pluginItem["type"]}</Tag></Col>
            <Col span={2} style={{textAlign: "right"}}>
                {isPluginInstalling ? <Button loading={true}>Installing</Button> : null }
                {!isPluginInstalling && pluginItem["is_installed"] ? <Button disabled={true}>Installed</Button> : null}
                {!isPluginInstalling && !pluginItem["is_installed"] ? <Button type={"primary"} onClick={() => handleInstallPlugin(pluginItem["id"])}>Install</Button>: null}
            </Col>
        </Row>
    </Card>);
}


function PluginView(props) {
    const {getPluginListRequest} = useApi();
    const [isPluginListLoading, setIsPluginListLoading] = useState(true);
    const [pluginList, setPluginList] = useState([]);

    const handlePluginList = async () => {
        setIsPluginListLoading(true);
        const pluginList = await getPluginListRequest();
        let pluginRenderList = [];

        for (let i = 0; i < pluginList.length; i++) {
            pluginRenderList.push(<PluginListItem key={pluginList[i]["id"]} pluginItem={pluginList[i]} onUpdate={handlePluginList} />);
        }

        setPluginList(pluginRenderList);
        setIsPluginListLoading(false);
    }


    useEffect(() => {
        handlePluginList();
    }, []);

    return (<div style={{background: "#fff", paddingLeft: "20px", paddingRight: "20px"}}>
        <Row>
            <Col span={24}>
                <h2>Plugins</h2>
                <Divider/>
                {isPluginListLoading ? <Skeleton active={true} /> : null}
                {!isPluginListLoading ? pluginList : null}
            </Col>
        </Row>
    </div>)
}


export default PluginView;

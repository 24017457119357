import React, {useMemo, useState} from "react";

import Select, {components} from "react-select";

import {

    getMiddleCenterBias,

    useTheme,

    GridCellKind,

    TextCellEntry

} from "@glideapps/glide-data-grid";


// Styles for the components

const styles = {

    wrap: {

        display: "flex",

        flexDirection: "column",

        alignItems: "stretch",

    },

    glideSelect: {

        fontFamily: "'Arial, sans-serif'",

        fontSize: "14px",

    },

    portalWrap: {

        fontFamily: "'Arial, sans-serif'",

        fontSize: "14px",

        color: "#333",

    },

    portalWrapDiv: {

        borderRadius: "4px",

        border: "1px solid #ccc",

    },

    readOnlyWrap: {

        display: "flex",

        margin: "auto 8.5px",

        paddingBottom: "3px",

    }

};


const CustomMenu = props => {

    const {Menu} = components;

    const {children, ...rest} = props;

    return <Menu {...rest}>{children}</Menu>;

};


const Editor = props => {

    const {value: cell, onFinishedEditing, initialValue} = props;

    const {allowedValues, value: valueIn} = cell.data;


    const [value, setValue] = useState(valueIn);

    const [inputValue, setInputValue] = useState(initialValue ?? "");


    const theme = useTheme();


    const values = useMemo(() => {

        return allowedValues.map(option => {

            if (typeof option === "string" || option === null || option === undefined) {

                return {value: option, label: option?.toString() ?? ""};

            }

            return option;

        });

    }, [allowedValues]);


    if (cell.readonly) {

        return (

            <div style={styles.readOnlyWrap}>

                <TextCellEntry

                    highlight={true}

                    autoFocus={false}

                    disabled={true}

                    value={value ?? ""}

                    onChange={() => undefined}

                />

            </div>

        );

    }


    return (

        <div style={styles.wrap}>

            <Select

                className="glide-select"

                inputValue={inputValue}

                onInputChange={setInputValue}

                menuPlacement={"auto"}

                value={values.find(x => x.value === value)}

                styles={{

                    control: base => ({

                        ...base,

                        border: 0,

                        boxShadow: "none",

                        ...styles.glideSelect,

                    }),

                    option: (base, {isFocused}) => ({

                        ...base,

                        fontSize: theme.editorFontSize,

                        fontFamily: theme.fontFamily,

                        cursor: isFocused ? "pointer" : undefined,

                        paddingLeft: theme.cellHorizontalPadding,

                        paddingRight: theme.cellHorizontalPadding,

                        ":active": {

                            ...base[":active"],

                            color: theme.accentFg,

                        },

                        ":empty::after": {

                            content: '"&nbsp;"',

                            visibility: "hidden",

                        },

                    }),

                }}

                theme={t => ({

                    ...t,

                    colors: {

                        ...t.colors,

                        neutral0: theme.bgCell,

                        neutral5: theme.bgCell,

                        neutral10: theme.bgCell,

                        neutral20: theme.bgCellMedium,

                        neutral30: theme.bgCellMedium,

                        neutral40: theme.bgCellMedium,

                        neutral50: theme.textLight,

                        neutral60: theme.textMedium,

                        neutral70: theme.textMedium,

                        neutral80: theme.textDark,

                        neutral90: theme.textDark,

                        neutral100: theme.textDark,

                        primary: theme.accentColor,

                        primary75: theme.accentColor,

                        primary50: theme.accentColor,

                        primary25: theme.accentLight,

                    },

                })}

                menuPortalTarget={document.getElementById("portal")}

                autoFocus={true}

                openMenuOnFocus={true}

                components={{

                    DropdownIndicator: () => null,

                    IndicatorSeparator: () => null,

                    Menu: props => (

                        <div style={styles.portalWrap}>

                            <div style={styles.portalWrapDiv}>

                                <CustomMenu className={"click-outside-ignore"} {...props} />

                            </div>

                        </div>

                    ),

                }}

                options={values}

                onChange={async e => {

                    if (e === null) return;

                    setValue(e.value);

                    await new Promise(r => window.requestAnimationFrame(r));

                    onFinishedEditing({

                        ...cell,

                        data: {

                            ...cell.data,

                            value: {d: e.value},

                        },

                    });

                }}

            />

        </div>

    );

};


const renderer = {

    kind: GridCellKind.Custom,

    isMatch: c => c.data.kind === "dropdown-cell",

    draw: (args, cell) => {

        const {ctx, theme, rect} = args;

        const {value} = cell.data;

        const foundOption = cell.data.allowedValues.find(opt => {

            if (typeof opt === "string" || opt === null || opt === undefined) {

                return opt === value;

            }

            return opt.value === value;

        });


        const displayText = typeof foundOption === "string" ? foundOption : foundOption?.label ?? "";

        if (displayText) {

            ctx.fillStyle = theme.textDark;

            ctx.fillText(displayText,

                rect.x + theme.cellHorizontalPadding,

                rect.y + rect.height / 2 + getMiddleCenterBias(ctx, theme));

        }

        return true;

    },

    measure: (ctx, cell, theme) => {

        const {value} = cell.data;

        return (value ? ctx.measureText(value).width : 0) + theme.cellHorizontalPadding * 2;

    },

    provideEditor: () => ({

        editor: Editor,

        disablePadding: true,

        deletedValue: v => ({

            ...v,

            copyData: "",

            data: {

                ...v.data,

                value: "",

            },

        }),

    }),

    onPaste: (v, d) => ({

        ...d,

        value: d.allowedValues.includes(v) ? v : d.value,

    }),

};


export default renderer;

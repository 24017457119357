import { CustomRenderer, GridCellKind } from "@glideapps/glide-data-grid";
import React, { useState } from "react";

const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    width: "100%",
};

const textareaStyle = {
    flexGrow: 1,
    marginRight: "4px", // Give some space for the button next to the textarea
};

// Simple renderer for demonstration purposes
const renderer = {
    kind: GridCellKind.Custom,
    isMatch: (c) => c.data.kind === "text-cell-custom",
    draw: (args, cell) => {
        // Drawing a textual representation of a button within the Canvas
        const { ctx, theme, rect } = args;
        const { value } = cell.data;

        console.log("draw custom cell = ", value);
        ctx.save();

        // Draw the text
        ctx.fillStyle = theme.textColor || "#000000";
        ctx.font = "14px Arial";
        ctx.fillText(value, rect.x + 5, rect.y + 17);

        // Placeholder for the button
        ctx.fillStyle = "#AAAAAA"; // Button Background
        ctx.fillRect(rect.x + rect.width - 20, rect.y + 5, 15, rect.height - 10);
        ctx.fillStyle = "#FFFFFF"; // Text or Icon Color
        ctx.font = "12px ";
        ctx.fillText("R", rect.x + rect.width - 18, rect.y + 17);

        ctx.restore();

        return true;
    },
    provideEditor: () => (props) => {
        const [value, setValue] = useState(props.value.data.value);

        const onChange = (event) => {
            setValue(event.target.value);
        };

        const onSave = () => {
            props.onSave({
                ...props.value,
                data: {
                    ...props.value.data,
                    value,
                },
            });
        };

        return (
            <div style={wrapperStyle}>
                <textarea
                    style={textareaStyle}
                    value={value}
                    onChange={onChange}
                />
                <button onClick={onSave}>
                    <i className="fas fa-bolt"></i>
                </button>
            </div>
        );
    },
};

export default renderer;

import "./style.css";

import {useEditor, EditorContent, FloatingMenu, BubbleMenu, ReactRenderer} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'

import suggestion from './suggestion.js'
import MentionList from "./MentionList";
import tippy from "tippy.js";
import {useEffect, useRef, useState} from "react";
import {getContextColumnListRequest} from "../requests";


const TriggerRichEditor = (props) => {
    const {name, onUpdate, tableId, content} = props;
    const mentionListRef = useRef([]);
    const [mentionList, setMentionList] = useState([ {
        "id": "loading",
        "label": 'Loading'
    }]);



    useEffect(() => {
        getContextColumnList();
    }, [tableId]);

    useEffect(() => {
        mentionListRef.current = mentionList;
    }, [mentionList]);




    const getContextColumnList = async () => {
        const response = await getContextColumnListRequest(tableId);
        const columnList = response["column_list"]

        let contextList = [];
        for (let i = 0; i < columnList.length; i++) {
            contextList.push({
                "id": `column:${columnList[i]["id"]}`,
                "label": columnList[i]["name"]
            })
        }
        // console.log("contextList = ", contextList);
        setMentionList(contextList);
    }


    let extensions = [
        StarterKit.configure({
            gapcursor: false,
            dropcursor: false,
        }),
        Mention.configure({
            HTMLAttributes: {
                class: 'mention',
            },
            suggestion: {
                items: ({ query }) => {

                    return mentionListRef.current.filter(item => item.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 10);
                },
                // items: ({ query }) => {
                //     return [
                //         {
                //             "id": "column:1",
                //             "label": 'Lea Thompson'
                //         }
                //     ]
                //         .filter(item => item.label.toLowerCase().startsWith(query.toLowerCase()))
                //         .slice(0, 5)
                // },
                render: () => {
                    let component
                    let popup

                    return {
                        onStart: props => {
                            console.log("onstart: ", props);
                            component = new ReactRenderer(MentionList, {
                                props,
                                editor: props.editor,
                            })

                            if (!props.clientRect) {
                                return
                            }

                            popup = tippy('body', {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start',
                            })
                        },

                        onUpdate(props) {
                            component.updateProps(props)

                            if (!props.clientRect) {
                                return
                            }

                            popup[0].setProps({
                                getReferenceClientRect: props.clientRect,
                            })
                        },

                        onKeyDown(props) {
                            if (props.event.key === 'Escape') {
                                popup[0].hide()

                                return true
                            }

                            return component.ref?.onKeyDown(props)
                        },

                        onExit() {
                            popup[0].destroy()
                            component.destroy()
                        },
                    }
                }
            }
        }),
    ];


    // console.log("editor = content = ", content);
    let editor = useEditor({
        extensions,
        content: content,
        onUpdate: ({ editor }) => {
            const updatedData = editor.getJSON();
            onUpdate(name, updatedData);
        },
        editorProps: {
            attributes: {
                class: 'richEditorCustom',
            },
        },
    })



    return (
        <span>
            <EditorContent editor={editor} />
            {/*<FloatingMenu editor={editor}>This is the floating menu</FloatingMenu>*/}
            {/*<BubbleMenu editor={editor}>This is the bubble menu</BubbleMenu>*/}
        </span>
    )
}

export default TriggerRichEditor

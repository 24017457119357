import React, {useCallback, useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    message, Progress,
    Row,
    Skeleton,
    Space, Spin,
    Switch, Tooltip,
    Typography,
    Upload
} from "antd";
import {
    DownloadOutlined,
    DownOutlined, FileExcelOutlined, FileOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    InboxOutlined,
    LoadingOutlined
} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import {getCellUpdatesForSelectedTableRequest, useApi} from "../utils/requests";
import {PLUGIN_API_HOST, TABLE_UPDATE_FREQUENCY} from "../config";
import dayjs from "dayjs";

const {Text} = Typography;
const {TextArea} = Input;

const executionSuccessStatus = "COMPLETED";


const styles = {
    container: {
        backgroundColor: '#f5f5f5',  // Light gray background
        border: '1px solid #d9d9d9', // Medium gray border
        borderRadius: '8px',
        padding: '24px',
        marginBottom: '20px'
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginTop: "0px",
        marginBottom: '16px',
        color: '#262626'  // Dark gray for title
    },
    text: {
        marginBottom: '16px',
        color: '#595959'  // Medium dark gray for text
    }
};

const LoadingWorkflowAlert = ({currentStep, totalSteps}) => {
    let shiftedCurrentStep = currentStep + 1;
    if (currentStep == totalSteps) {
        shiftedCurrentStep = currentStep;
    }
    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Workflow in progress ...</h2>
            <div style={styles.text}>
                Content generation is in progress. Current step: {shiftedCurrentStep} of {totalSteps}
            </div>
            <Progress
                percent={Math.round((shiftedCurrentStep / totalSteps) * 100)}
                status="active"
                showInfo={false}
                strokeColor="#40a9ff"  // Keep progress bar blue for visual interest
            />
        </div>
    );
};



const FileDownloadFormItem = ({ fileUrl, disabled }) => {
    const getFileNameAndType = (url) => {
        if (!url) return { fileName: null, fileType: null };
        try {
            const parts = url.split('/');
            const fullFileName = parts[parts.length - 1];
            const [fileName, fileExt] = fullFileName.split('.');
            return { fileName: fullFileName, fileType: fileExt };
        } catch (error) {
            console.error("Error parsing file URL:", error);
            return { fileName: null, fileType: null };
        }
    };

    const getFileIcon = (type) => {
        if (!type) return <FileOutlined />;
        switch (type.toLowerCase()) {
            case 'pdf':
                return <FilePdfOutlined />;
            case 'doc':
            case 'docx':
                return <FileWordOutlined />;
            case 'xls':
            case 'xlsx':
                return <FileExcelOutlined />;
            default:
                return <FileOutlined />;
        }
    };

    console.log("FileDownloadFormItem = ", fileUrl);
    const { fileName, fileType } = getFileNameAndType(fileUrl);

    if (!fileName || !fileType) {
        return (
            <Form.Item>
                {getFileIcon("other")}
                <Text>&nbsp; File not exist &nbsp;</Text>
                <Button
                    type="primary"
                    disabled={true}
                    icon={<DownloadOutlined />}
                >
                    Download
                </Button>
            </Form.Item>
        );
    }

    return (
        <Form.Item>
            <Space>
                {getFileIcon(fileType)}
                <Text>{fileName}</Text>
                <Button
                    type="primary"
                    disabled={disabled}
                    icon={<DownloadOutlined />}
                    onClick={() => {
                        if (fileUrl) {
                            window.open(fileUrl, '_blank');
                        } else {
                            alert("File URL is not available.");
                        }
                    }}
                >
                    Download
                </Button>
            </Space>
        </Form.Item>
    );
};

function InterfaceFormPreview(props) {
    const {
        interfaceId, interfaceData, settingFormData, formSettingFormData,
        updateId, tableId, frontendRowId, rowData, rawRowData, workflowsFormData
    } = props;
    const {runWorkflowRequest, getWorkflowStatusByFrontendRowIdRequest} = useApi();
    const lastUpdateDateRef = React.useRef(null);
    const [form] = Form.useForm();

    const [formRender, setFormRender] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [executionInProgress, setExecutionInProgress] = useState(null);
    const [isWorkflowStarting, setIsWorkflowStarting] = useState(false);
    const [workflowInProcess, setWorkflowInProcess] = useState(false);

    const [rawData, setRawData] = useState(null);

    const selectedVersionId = interfaceData["version"]["id"];

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const processUpdates = (cellList) => {
        let cloneRawData = Object.assign({}, rawData);

        for (let i = 0; i < cellList.length; i++) {
            const cellValue = JSON.parse(cellList[i]["data"]);
            form.setFieldValue(`column:${cellList[i]["column_id"]}`, cellValue["d"]);
            cloneRawData[cellList[i]["column_id"]]["d"] = cellValue["d"];
        }
        setRawData(cloneRawData);
    }

    async function askCellUpdates() {
        const response = await getCellUpdatesForSelectedTableRequest(tableId, lastUpdateDateRef.current, frontendRowId);
        lastUpdateDateRef.current = dayjs().format('YYYY-MM-DDTHH:mm:ssZ');

        const cellChangesList = response["data"];
        console.log("cellChangesList = ", cellChangesList);
        processUpdates(cellChangesList);
    }

    const getWorkflowStatus = useCallback(async () => {
        console.log("[InterfaceForm] getWorkflowStatus = ", executionInProgress, frontendRowId);
        if (executionInProgress) {
            const {
                isOk,
                responseData
            } = await getWorkflowStatusByFrontendRowIdRequest(executionInProgress.id, frontendRowId);
            await askCellUpdates();

            console.log('responseData = ', isOk, responseData);
            const workflowExecutionObject = responseData["workflow_execution"]["data"];

            if (workflowExecutionObject["status"] == executionSuccessStatus) {
                console.log("[InterfaceForm] getWorkflowStatus: clear interval");
                // await sleep(5000);
                // await askCellUpdates();

                setExecutionInProgress(null);
                setWorkflowInProcess(false);
            } else {
                console.log("workflow_execution = ", workflowExecutionObject);
                setExecutionInProgress(workflowExecutionObject);
            }

            // Add logic here to handle the response and potentially clear executionInProgressId
            // For example:
            // if (responseData.status === 'completed' || responseData.status === 'failed') {
            //     setExecutionInProgressId(null);
            // }
        }
    }, [executionInProgress?.id, frontendRowId, getWorkflowStatusByFrontendRowIdRequest]);

    useEffect(() => {
        let intervalId;

        if (executionInProgress && executionInProgress["status"] != executionSuccessStatus) {
            intervalId = setInterval(getWorkflowStatus, TABLE_UPDATE_FREQUENCY);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [executionInProgress?.id, getWorkflowStatus]);

    useEffect(() => {
        if (workflowsFormData.length > 0) {
            const workflowExecutionObject = workflowsFormData[0]['last_workflow_execution'];
            setExecutionInProgress(workflowExecutionObject);
            const apiWorkflowInProgress = (workflowExecutionObject != null && workflowExecutionObject["status"] != executionSuccessStatus)
            setWorkflowInProcess(apiWorkflowInProgress);
        }
    }, [workflowsFormData]);


    useEffect(() => {
        // console.log("[InterfaceForm] useEffect = ", settingFormData, formSettingFormData);
        let settingsFieldMap = {};

        const settingKeyList = Object.keys(settingFormData);
        const pluginFieldSchemaMap = interfaceData["account_plugin"]["field_schema"];
        const columnList = interfaceData["account_table_column_list"]

        // let isEnabledKeys = settingKeyList.filter(item => item.endsWith('is_enabled'));
        let formJsonList = []
        let formRenderList = [];
        for (let i = 0; i < columnList.length; i++) {
            const columnItem = columnList[i];
            const columnId = columnItem["id"];
            const columnEnabledKey = `column:${columnId}:is_enabled`
            if (settingFormData[columnEnabledKey]) {
                let schemaMap = {}
                const settingsFieldSchemaList = pluginFieldSchemaMap[columnItem["frontend_type"]];
                if (settingsFieldSchemaList != undefined) {
                    for (let j = 0; j < settingsFieldSchemaList.length; j++) {
                        const fieldValueKey = `column:${columnId}:field:${settingsFieldSchemaList[j]["id"]}`;
                        const fieldValue = settingFormData[fieldValueKey];
                        schemaMap[settingsFieldSchemaList[j]["field_key"]] = fieldValue;
                    }
                }

                const formItem = {
                    id: columnId,
                    frontendType: columnItem["frontend_type"],
                    defaultValues: columnItem["default_value_json"],
                    schemaMap: schemaMap
                }

                formJsonList.push(formItem);

                let formInputArea = <span>None</span>
                if (formItem.frontendType == "INPUT") {
                    formInputArea = <TextArea disabled={workflowInProcess} autoSize/>;
                }

                if (formItem.frontendType == "FILE") {
                    const defaultValue = rowData[`column:${columnId}`]

                    let defaultValueList = null;
                    if (defaultValue != null && defaultValue != undefined && defaultValue.length > 0) {
                        defaultValueList = [
                            {
                                uid: columnId,
                                name: defaultValue,
                                status: 'done',
                            }
                        ];
                    }

                    console.log("FILES : defaultValueList = ", defaultValueList);

                    const draggerProps = {
                        name: 'file',
                        multiple: false,
                        action: `${PLUGIN_API_HOST}/file_plugin/upload`,
                        defaultFileList: defaultValueList,
                        disabled: workflowInProcess,
                        maxCount: 1,
                        data: {
                            "tableId": tableId,
                            "rowFrontendId": frontendRowId,
                            "columnId": columnId,
                        },
                        onChange(info) {
                            const {status} = info.file;
                            if (status !== 'uploading') {
                                console.log(info.file, info.fileList);
                            }
                            if (status === 'done') {
                                message.success(`${info.file.name} file uploaded successfully.`);
                                console.log("[InterfaceForm] file. status done = ", info.file.response);
                                // cell.onChange(info.file.response);

                                // props.onFileUpdate(columnId, info.file.response);
                            } else if (status === 'error') {
                                message.error(`${info.file.name} file upload failed.`);
                            }
                        },
                        onDrop(e) {
                            console.log('Dropped files', e.dataTransfer.files);
                        },
                    };

                    formInputArea = (<Upload.Dragger {...draggerProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Upload.Dragger>);
                }


                if (formItem.frontendType == "DOWNLOAD_LINK") {
                    formInputArea = (<FileDownloadFormItem fileUrl={rawData == null ? null : rawData[formItem.id]["d"]} disabled={workflowInProcess} />);
                }

                const formItemName = formItem.schemaMap["name"];
                const formItemDescription = formItem.schemaMap["description"];

                const formInputItem = <Form.Item name={`column:${formItem.id}`} noStyle>
                    {formInputArea}
                </Form.Item>

                let formRenderDescription = null;
                if (formItemDescription != undefined) {
                    formRenderDescription = <Typography.Text type="secondary">{formItemDescription}</Typography.Text>
                }

                let formItemRender = null;
                if (formItemName != undefined && formItemName.length > 0) {
                    formItemRender = <Form.Item label={`${formItemName}:`}>
                        {formInputItem}
                        {formRenderDescription}
                    </Form.Item>;
                } else {
                    formItemRender = <Form.Item noStyle>
                        {formInputItem}
                        {formRenderDescription}
                    </Form.Item>;
                }
                formRenderList.push(formItemRender);
            }
        }
        console.log("formJsonList = ", formJsonList, rawData);
        lastUpdateDateRef.current = dayjs().format('YYYY-MM-DDTHH:mm:ssZ');
        setFormRender(formRenderList);

    }, [updateId, workflowInProcess, rawData]);


    useEffect(() => {
        setRawData(rawRowData);
    }, [interfaceId, frontendRowId, rawRowData]);

    const handleFormChanges = (values) => {
        props.onValuesUpdate(values);
    }

    const handleRunWorkflow = async () => {
        setIsWorkflowStarting(true);
        if (workflowsFormData.length > 0) {
            const workflowId = workflowsFormData[0]["account_workflow_id"];
            const {isOk, responseData} = await runWorkflowRequest(workflowId, frontendRowId);

            console.log("handleRunWorkflow = ", responseData);
            if (isOk && responseData["workflow_execution"]["data"]["id"]) {
                setExecutionInProgress(responseData["workflow_execution"]["data"]);
                const apiWorkflowInProgress = (responseData["workflow_execution"]["data"]["status"] != executionSuccessStatus)
                setWorkflowInProcess(apiWorkflowInProgress);

                if (apiWorkflowInProgress) {
                    await askCellUpdates();
                }
            }
        }
        setIsWorkflowStarting(false);
    }


    if (isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>)
    }


    const isWorkflowSelected = formSettingFormData["workflow_connected_button"] != null && parseInt(formSettingFormData["workflow_connected_button"]) > 0;

    const workflowButton = <Button type="primary" size="large" onClick={handleRunWorkflow} loading={isWorkflowStarting}
                                   disabled={!isWorkflowSelected || workflowInProcess}>
        {formSettingFormData["workflow_button_text"]}
    </Button>;

    let workflowContainer = workflowButton;

    if (!isWorkflowSelected) {
        workflowContainer = (<Tooltip title="You must select a workflow to activate this button.">
            {workflowContainer}
        </Tooltip>)
    }

    // let workflowInProcess = (executionInProgress != null && executionInProgress["status"] != executionSuccessStatus);
    // let workflowInProcess = true;

    return (<div style={{
        backgroundColor: "#f6f6f6",
        minHeight: "calc(100vh - 62.71px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }}>
        <Card title={formSettingFormData["form_name"]}
              style={{width: "700px", margin: "0px auto", marginTop: "25px", marginBottom: "25px"}}>

            <div style={{marginBottom: "20px"}}>

                {formSettingFormData["form_description"] != null && formSettingFormData["form_description"].length > 0 ?
                    <Alert
                        // message="Informational Notes"
                        description={formSettingFormData["form_description"].split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br/>
                            </React.Fragment>
                        ))}
                        type="info"
                        showIcon
                    /> : null}

            </div>

            <Form
                form={form}
                layout="vertical"
                initialValues={rowData}
                onValuesChange={handleFormChanges}
            >
                {formRender}

                <div style={{marginTop: "20px", marginBottom: "24px"}}>
                    {workflowInProcess ? <div>
                        {/*{JSON.stringify(executionInProgress)}*/}
                        <LoadingWorkflowAlert currentStep={executionInProgress["current_step_number"]}
                                              totalSteps={executionInProgress["step_amount"]}/>
                    </div> : null}
                    {workflowContainer}
                </div>

                {/*<div>*/}
                {/*    JSON: <br/>*/}
                {/*    {JSON.stringify(rawData)}*/}
                {/*</div>*/}
            </Form>
        </Card>
    </div>)
}

export default InterfaceFormPreview;

import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import {useEffect, useState} from "react";
import {ENV_API_HOST} from "../config";
import {ColumnEditForm, NewTriggerForm} from "../utils/Forms";
import {getColumnTypeDetailsRequest, useApi} from "../utils/requests";
import ColumnPopupFieldsManager from "./ColumnPopupFieldsManager";

const NewColumnPopup = (props) => {
    const {typeList, tableId} = props;
    const {postColumnForTableRequest} = useApi();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mapList, setMapList] = useState([]);
    const [isColumnCreating, setIsColumnCreating] = useState(false);
    const [columnTypeId, setColumnTypeId] = useState(null);

    const [triggerFormData, setTriggerFormData] = useState({});
    const [columnSettings, setColumnSettings] = useState({});

    const [form] = Form.useForm();



    useEffect(() => {
        mapTypeList();
    }, [props?.typeList]);

    const mapTypeList = () => {
        let mappedList = [];
        let selectedColumnKey = null;
        for (let i = 0; i < typeList.length; i++) {
            if (i == 0) {
                selectedColumnKey =  typeList[i]["key"]
            }
            mappedList.push({
                value: typeList[i]["key"],
                label: typeList[i]["label"]
            })
        }
        setColumnTypeId(selectedColumnKey);
        setMapList(mappedList);
    }

    const createColumn = async (formData) => {
        setIsColumnCreating(true);
        formData["trigger"] = triggerFormData;
        formData["column_settings"] = columnSettings;
        await postColumnForTableRequest(tableId, formData);
        props.onUpdate();
        setIsColumnCreating(false);
    }


    const showModal = () => {
        setIsModalOpen(true);
    };

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    const handleOk = async () => {
        // form.submit();

        form.validateFields()
            .then((values) => {
                console.log("value = ", values);
                createColumn(values)
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });

        // setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleColumnFormChanges = (changedValues, allValues) => {
      console.log("handleColumnFormChanges = ", changedValues);
      if (changedValues["type"] != null) {
          setColumnTypeId(changedValues["type"]);
      }
    }

    const handleTriggerFormChanges = (formType, formData) => {
        console.log("[NewColumnPopop] handleTriggerFormChanges : ",formType, formData);
        if (formType == "trigger") {
            setTriggerFormData(formData);
        }

        if (formType == "columnSettings") {
            setColumnSettings(formData);
        }
    }


    return (<span>
            <div className="addCol">
                <button onClick={showModal}>
                    +
                </button>
            </div>
            <Modal
                title="New field"
                open={isModalOpen}
                onOk={handleOk}
                cancelText={"Cancel"}
                okText={"Create field"}
                onCancel={handleCancel}>
                <ColumnEditForm form={form} initValue={{type: columnTypeId}} onSubmit={null} handleFormChanges={handleColumnFormChanges}/>

                <ColumnPopupFieldsManager
                    isEditing={false}
                    tableId={tableId}
                    accountMetaColumnId={null}
                    columnTypeId={columnTypeId}
                    onUpdate={handleTriggerFormChanges}
                />

                {/*<NewTriggerForm tableId={tableId} triggerData={{"init_event_type": "ON_MANUAL_RUN"}} columnTypeId={columnTypeId} onFormUpdate={handleTriggerFormChanges} />*/}
            </Modal>
        </span>);

};

export default NewColumnPopup;

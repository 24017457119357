import {Col, Divider, Form, Row, Spin, Input, Checkbox, Button, Card} from "antd";
import {useEffect, useState} from "react";
import "../unauth.css";
import usePublicApi from "../utils/publicRequests";
import {Link, useNavigate} from "react-router-dom";

function SigninView(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {postSignin} = usePublicApi();
    const [isLoading, setIsLoading] = useState(false);

    const handleSigninRequest  = async (postData) => {
        setIsLoading(true);
        const responseData = await postSignin(postData);
        const isOk = responseData[0];
        const response = responseData[1];

        if (isOk) {
            console.log("setup jwt")
            localStorage.setItem('jwtToken', response["access"]);
            setIsLoading(false);
            navigate("/");
        }

        setIsLoading(false);

    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);

        handleSigninRequest(values);

    };

    return (
        <div style={{
            width: '100%',
            background: "#f5f5f5",
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Card title="Sign in" style={{width: "400px"}}>

                <Form
                    form={form}
                    name="login"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input size={"large"} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password size={"large"} placeholder="Password"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Sign in
                        </Button>
                    </Form.Item>
                </Form>

                <Row>
                    <Col span={24}><Link to={"/signup"}>Signup</Link> or <Link to={"/forgot-password"}>Forgot password?</Link></Col>
                </Row>
            </Card>

        </div>
    );
}


export default SigninView;

import {CustomRenderer, GridCellKind} from "@glideapps/glide-data-grid";
import React, {useState} from "react";
import {InboxOutlined} from '@ant-design/icons';
import {message, Upload} from 'antd';
import {PLUGIN_API_HOST} from '../../config'


const {Dragger} = Upload;


const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    width: "100%",
};

const textareaStyle = {
    flexGrow: 1,
    marginRight: "4px", // Give some space for the button next to the textarea
};

// Simple renderer for demonstration purposes
const renderer = {
    kind: GridCellKind.Custom,
    isMatch: (c) => c.data.kind === "file-cell",
    draw: (args, cell) => {
        // Drawing a textual representation of a button within the Canvas
        const {ctx, theme, rect} = args;
        const {value} = cell.data;

        console.log("draw custom cell = ", value);
        ctx.save();

        // Draw the text
        ctx.fillStyle = theme.textColor || "#000000";
        ctx.font = "13px ";
        ctx.fillText(value, rect.x + 5, rect.y + 17);

        // Placeholder for the button
        // ctx.fillStyle = "#AAAAAA"; // Button Background
        // ctx.fillRect(rect.x + rect.width - 20, rect.y + 5, 15, rect.height - 10);
        // ctx.fillStyle = "#FFFFFF"; // Text or Icon Color
        // ctx.font = "11px ";
        // ctx.fillText("R", rect.x + rect.width - 18, rect.y + 17);

        ctx.restore();

        return true;
    },

    // provideEditor={cell => {
    //     // add whatever other criteria you need to this condition to show this for just your file upload cell
    //     if (cell.kind !== GridCellKind.Custom) return undefined
    //
    //     return p => {
    //
    //         const inputRef = React.useRef<HTMLInputElement>()
    //
    //         // add effects/hooks/etc. to handle uploading when a file is selected
    //         // you could also add a inputRef.current.click() useEffect to automatically click the upload
    //
    //         return <div>
    //             <input type="file" ref={inputRef}></input>
    //         </div>
    //     }
    // }}


    provideEditor: () => (cell) => {
        console.log("[provideEditor] cell = ", cell);

        const onValueUpdated = (fileData) => {

            const value = {
                "d": fileData.filename,
                "m": {
                    "ldg": false,
                    "dsbl": null,
                    "d": {
                        "path": fileData.path,
                        "public_path": fileData.public_path,
                        "size": fileData.size,
                    }
                }
            }


            cell.onFinishedEditing({
                ...cell.value,
                data: {
                    ...cell.value.data,
                    value,
                },
            });
        };

        const props = {
            name: 'file',
            multiple: false,
            action: `${PLUGIN_API_HOST}/file_plugin/upload`,
            data: {
                "tableId": parseInt(cell.value.data.tableId),
                "rowFrontendId": cell.value.data.rowId,
                "columnId": cell.value.data.columnId,
            },
            onChange(info) {
                const {status} = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                    // console.log("status done = ", info.file.response);
                    // cell.onChange(info.file.response);
                    onValueUpdated(info.file.response);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };


        const inputRef = React.useRef();

        return (<div>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                {/*<p className="ant-upload-hint">*/}
                {/*    Support for a single or bulk upload. */}
                {/*</p>*/}
            </Dragger>
        </div>)

    }



    // provideEditor: () => (props) => {
    //     const [value, setValue] = useState(props.value.data.value);
    //
    //     const onChange = (event) => {
    //         setValue(event.target.value);
    //     };
    //
    //     const onSave = () => {
    //         props.onSave({
    //             ...props.value,
    //             data: {
    //                 ...props.value.data,
    //                 value,
    //             },
    //         });
    //     };
    //
    //     return (
    //         <div style={wrapperStyle}>
    //             <textarea
    //                 style={textareaStyle}
    //                 value={value}
    //                 onChange={onChange}
    //             />
    //             <button onClick={onSave}>
    //                 <i className="fas fa-bolt"></i>
    //             </button>
    //         </div>
    //     );
    // },
};

export default renderer;

import React, { createContext, useContext, useState } from 'react';
import { ConfigProvider, theme } from 'antd';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [direction, setDirection] = useState('ltr');

    const toggleTheme = () => setIsDarkMode(!isDarkMode);
    const toggleDirection = (newValue) => setDirection(newValue);

    return (
        <ThemeContext.Provider value={{ isDarkMode, direction, toggleTheme, toggleDirection }}>
            <ConfigProvider
                theme={{
                    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                }}
                direction={direction}
            >
                {children}
            </ConfigProvider>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);

import {Link, useLocation} from "react-router-dom";
import {Button, Dropdown, Form, Input, Menu, Modal, Spin} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEllipsis, faTable, faWindowMaximize} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "../config";
import {deleteTableRequest, useApi} from "./requests";
import {LoadingOutlined} from "@ant-design/icons";
import {ColumnEditForm, NewTriggerForm} from "./Forms";


const WorkspaceTableMenu = (props) => {
    const {isCreatingTable} = props;
    const {getTableListRequest} = useApi();
    const location = useLocation();
    const [tableMenuRenderList, setTableMenuRenderList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("WorkspaceTableMenu: getTableList");
        getTableList();
    }, []);


    useEffect(() => {
        getTableList();
    }, [props?.newTableId]);


    const handleUpdate = () => {
      getTableList();
    }

    const handleChangeIconWindow = () => {
        console.log("handleChangeIconWindow")
    }

    const getTableList = async () => {
        setIsLoading(true);
        const tableList = await getTableListRequest();
        let menuRenderList = [];

        for (let i = 0; i < tableList.length; i++) {
            const tableItem = tableList[i];
            menuRenderList.push(<Menu.Item style={{"position": "relative", paddingLeft: "16px"}} key={tableItem["id"]}>
                <span style={{paddingRight: "5px"}}><a onClick={handleChangeIconWindow}><FontAwesomeIcon icon={faTable} /></a></span>
                <span style={{marginRight: "5px"}}>
                    <Link to={`/table/${tableItem["id"]}/`}>
                    {tableItem["name"]}
                </Link>
                </span>
                {/*<Input />*/}
                <WorkspaceTableTabDropdown tableItem={tableItem} onUpdate={handleUpdate} />
            </Menu.Item>)
        }
        setTableMenuRenderList(menuRenderList);
        setIsLoading(false);
    }


    const match = location.pathname.match(/\/table\/(\d+)/);

    const selectedTableKey = match ? match[1] : null;

    return (<Menu theme="dark" mode="inline" selectedKeys={[selectedTableKey]}>
        {tableMenuRenderList}
        {/*<Skeleton />*/}
        <Menu.Item style={{"position": "relative", paddingLeft: "16px"}} key={"creating-a-new-table"} disabled={true}>
            {isCreatingTable ? <span>
                <span style={{paddingRight: "5px"}}><Spin size={"small"} indicator={<LoadingOutlined style={{fontSize: 16}} spin/>}/></span>
                    Creating a new table ...
            </span> : null}

        </Menu.Item>
    </Menu>);

}



const WorkspaceInterfaceMenu = (props) => {
    const {isInterfaceCreation} = props;
    const {getInterfaceListRequest} = useApi();
    const location = useLocation();
    const [tableMenuRenderList, setTableMenuRenderList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("WorkspaceTableMenu: getTableList");
        getTableList();
    }, []);


    useEffect(() => {
        getTableList();
    }, [props?.newId]);


    const handleUpdate = () => {
        getTableList();
    }

    const handleChangeIconWindow = () => {
        console.log("handleChangeIconWindow")
    }

    const getTableList = async () => {
        setIsLoading(true);
        const tableList = await getInterfaceListRequest();
        let menuRenderList = [];

        for (let i = 0; i < tableList.length; i++) {
            const item = tableList[i];
            menuRenderList.push(<Menu.Item style={{"position": "relative", paddingLeft: "16px"}} key={item["id"]}>
                <span style={{paddingRight: "5px"}}><a onClick={handleChangeIconWindow}><FontAwesomeIcon icon={faWindowMaximize} /></a></span>
                <span style={{marginRight: "5px"}}>
                    <Link to={`/interface/${item["id"]}/`}>
                        {item["name"]}
                    </Link>
                </span>
                {/*<Input />*/}
                <WorkspaceInterfaceTabDropdown interfaceItem={item} onUpdate={handleUpdate} />
            </Menu.Item>)
        }
        setTableMenuRenderList(menuRenderList);
        setIsLoading(false);
    }


    const match = location.pathname.match(/\/interface\/(\d+)/);

    const selectedKey = match ? match[1] : null;

    return (<Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
        {tableMenuRenderList}
        {/*<Skeleton />*/}
        <Menu.Item style={{"position": "relative", paddingLeft: "16px"}} key={"creating-a-new-interface"} disabled={true}>
            {isInterfaceCreation ? <span>
                <span style={{paddingRight: "5px"}}><Spin size={"small"} indicator={<LoadingOutlined style={{fontSize: 16}} spin/>}/></span>
                    Creating a new interface ...
            </span> : null}

        </Menu.Item>
    </Menu>);

}

//
const EditTableMetaData = (props) => {
    const {tableId} = props;
    const {putTableMetaDataRequest} = useApi();

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const updateTableData = async (values) => {
        isLoading(true);
        await putTableMetaDataRequest(tableId, values["title"]);
        isLoading(false);
    }

    const onFinish = (values) => {
        console.log("[EditTableMetaData] finish = ", values);
        updateTableData(values);
    };

    return (
        <div style={{background: "#1677ff", padding: "5px 5px", color: "#fff"}}>
            <Form form={form} onFinish={onFinish} layout="inline">
                <Form.Item name="title" style={{margin: 0}}>
                    <Input style={{color: "#fff", width: "160px"}} placeholder="Type a new table name here"
                           variant="borderless"/>
                </Form.Item>
                {!isLoading ? <Form.Item style={{margin: 0}}>
                    <Button type="link" style={{color: "#fff", padding: "0px 5px"}} htmlType="submit">
                        <FontAwesomeIcon icon={faCheck}/>
                    </Button>
                </Form.Item> : <Form.Item style={{margin: 0, paddingLeft: "5px"}}>
                    <Spin indicator={<LoadingOutlined style={{fontSize: 16, color: "#fff"}} spin/>}/>
                </Form.Item>}

            </Form>
        </div>
    );
};



const WorkspaceInterfaceTabDropdown = (props) => {
    const {interfaceItem, onUpdate} = props;
    const {putInterfaceMetaDataRequest, deleteInterfaceRequest} = useApi();
    const tableId = interfaceItem["id"];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => {
        form.setFieldsValue(interfaceItem);
        setIsModalOpen(true);
    }


    const handleDelete = async () => {
        console.log("handleDelete")
        await deleteInterfaceRequest(tableId);
        onUpdate();
    }

    const dropdownItemList = {
        "items": [
            {
                key: '0',
                disabled: (interfaceItem["slug"] == null),
                label: (
                    <a target="_blank" rel="noopener noreferrer" href={`/i/${interfaceItem["slug"]}`}>
                        Public link
                    </a>
                ),
            },
            {
                key: '1',
                label: (
                    <a  rel="noopener noreferrer" onClick={handleOpen}>
                        Rename
                    </a>
                ),
            },
            {
                key: '2',
                disabled: true,
                label: (
                    <a target="_blank" rel="noopener noreferrer" >
                        Change an icon
                    </a>
                ),
            },
            {
                key: '3',
                danger: true,
                label: (
                    <a  rel="noopener noreferrer" onClick={() => {
                        Modal.confirm({
                            onOk: handleDelete,
                            title: 'Confirm Interface Deletion',
                            content: 'If you delete this interface, you cannot recover it.',
                            footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            ),
                        });
                    }}>
                        Delete
                    </a>
                ),
            },
        ]
    };

    const updateInterfaceData = async (values) => {
        setIsLoading(true);
        await putInterfaceMetaDataRequest(tableId, values["name"]);
        handleCancel();
        form.resetFields();
        onUpdate();
        setIsLoading(false);
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("value = ", values);
                updateInterfaceData(values);

            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }

    const onFinish = (values) => {

    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    return (<span style={{position: "absolute", right: "8px"}}>
        <Dropdown menu={dropdownItemList} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
                <FontAwesomeIcon icon={faEllipsis}/>
            </a>
        </Dropdown>
             <Modal
                 title="Interface rename"
                 open={isModalOpen}
                 onOk={handleOk}
                 cancelText={"Cancel"}
                 okText={"Rename"}
                 confirmLoading={isLoading}
                 onCancel={handleCancel}
             >
            <Form form={form} onFinish={onFinish}>
                <Form.Item name="name" >
                    <Input placeholder="Type a new interface name here"/>
                </Form.Item>
            </Form>
        </Modal>
    </span>
    )


}



const WorkspaceTableTabDropdown = (props) => {
    const {tableItem, onUpdate} = props;
    const {putTableMetaDataRequest, deleteTableRequest} = useApi();
    const tableId = tableItem["id"];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    
    const handleOpen = () => {
        form.setFieldsValue(tableItem);
        setIsModalOpen(true);
    }
    

    const handleDelete = async () => {
        console.log("handleDelete")
        await deleteTableRequest(tableId);
        onUpdate();
    }

    const dropdownItemList = {
        "items": [
            {
                key: '1',
                label: (
                    <a  rel="noopener noreferrer" onClick={handleOpen}>
                        Rename
                    </a>
                ),
            },
            {
                key: '2',
                disabled: true,
                label: (
                    <a target="_blank" rel="noopener noreferrer" >
                        Change an icon
                    </a>
                ),
            },
            {
                key: '3',
                danger: true,
                label: (
                    <a  rel="noopener noreferrer" onClick={() => {
                        Modal.confirm({
                            onOk: handleDelete,
                            title: 'Confirm Table Deletion',
                            content: 'If you delete this table, you cannot recover it.',
                            footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            ),
                        });
                    }}>
                        Delete
                    </a>
                ),
            },
        ]
    };

    const updateTableData = async (values) => {
        setIsLoading(true);
        await putTableMetaDataRequest(tableId, values["name"]);
        handleCancel();
        form.resetFields();
        onUpdate();
        setIsLoading(false);
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("value = ", values);
                updateTableData(values);

            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }
    
    const onFinish = (values) => {

    }

    const handleCancel = () => {
      setIsModalOpen(false);
    }

    return (<span style={{position: "absolute", right: "8px"}}>
        <Dropdown menu={dropdownItemList} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
                <FontAwesomeIcon icon={faEllipsis}/>
            </a>
        </Dropdown>
             <Modal
                 title="Table rename"
                 open={isModalOpen}
                 onOk={handleOk}
                 cancelText={"Cancel"}
                 okText={"Rename"}
                 confirmLoading={isLoading}
                 onCancel={handleCancel}
             >
            <Form form={form} onFinish={onFinish}>
                <Form.Item name="name" >
                    <Input placeholder="Type a new table name here"/>
                </Form.Item>
            </Form>
        </Modal>
    </span>
    )


}

export {
    WorkspaceTableMenu,
    WorkspaceInterfaceMenu
};

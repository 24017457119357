import {Button, Card, Col, Form, Input, Modal, Row, Select, Skeleton} from "antd";
import {useEffect, useState} from "react";
import {ENV_API_HOST} from "../config";
import {ColumnEditForm, ColumnSettingsForm, NewTriggerForm} from "../utils/Forms";
import {useApi} from "../utils/requests";
import {triggerDataToFormSerialization} from "../utils/etc";

const pluginFieldDataNormalization = (apiData) => {
    const apiPluginFieldData = apiData["plugin_field_list"];
    let formTriggerList = [];
    for (let i = 0; i < apiPluginFieldData.length; i++) {
        const formTriggerData = triggerDataToFormSerialization(apiPluginFieldData[i]);
        formTriggerList.push(formTriggerData);
    }

    // todo: hotfix: because form doesnt render empty values
    // if (formTriggerList.length == 0 && apiData["plugin_id"] != null) {
    if (formTriggerList.length == 0) {
        formTriggerList = [{"init_event_type": "ON_MANUAL_RUN"}]
    }

    return formTriggerList;
}

const ColumnPopupFieldsManager = (props) => {
    const {tableId, accountMetaColumnId, columnTypeId, isEditing} = props;
    const {getColumnTypeDetailsRequest, getSelectedColumnTypeDataRequest } = useApi();

    const [columnTypeDetails, setColumnTypeDetails] = useState({});

    const [pluginFieldData, setPluginFieldData] = useState({});
    const [columnSettingsFieldData, setColumnSettingsFieldData] = useState({});

    const [triggerDataSnapshot, setTriggerDataSnapshot] = useState(null);


    const [isColumnDetailsLoading, setIsColumnDetailsLoading] = useState(true);

    const getColumnDetails = async (columnTypeId) => {
        console.log("[ColumnPopupFieldsManager.js] getColumnDetails", columnTypeId);
        setIsColumnDetailsLoading(true);
        const columnTypeData = await getColumnTypeDetailsRequest(columnTypeId);
        console.log("getColumnDetails:columnTypeData = ", columnTypeData["column_type"]);
        setColumnTypeDetails(columnTypeData["column_type"])

        if(isEditing) {
            const dataResponse = await getSelectedColumnTypeDataRequest(accountMetaColumnId, columnTypeId);
            console.log("[ColumnPopupFieldsManager] dataResponse: ", dataResponse);
            const pluginFieldValueList = pluginFieldDataNormalization(dataResponse);
            setPluginFieldData(pluginFieldValueList[0]);
            setTriggerDataSnapshot(pluginFieldValueList[0]);
            props.onUpdate("trigger", pluginFieldValueList[0]);

            setColumnSettingsFieldData(dataResponse["column_settings_list"]);
            props.onUpdate("columnSettings", dataResponse["column_settings_list"]);
            // setColumnTypeData(dataResponse);
        }
        setIsColumnDetailsLoading(false);
    }


    useEffect(() => {
        console.log("[useEffect] props?.columnTypeId = ", props?.columnTypeId);
        const {columnTypeId} = props;
        if (columnTypeId != null) {
            getColumnDetails(columnTypeId);
        }
    }, [props?.columnTypeId, accountMetaColumnId]);


    useEffect(() => {
        if(!isEditing) {
            setPluginFieldData({"init_event_type": "ON_MANUAL_RUN"})
        }
    }, [])
    //
    // useEffect(() => {
    //     console.log("[useEffect][zero_condition] props?.columnTypeId = ", props?.columnTypeId);
    //     const {columnTypeId} = props;
    //     if (columnTypeId != null) {
    //         getColumnDetails(columnTypeId);
    //     }
    // }, []);



    const handleTriggerFormChanges = (formData) => {
        console.log("[ColumnPopupFieldsManager] handleTriggerFormChanges : ", formData);
        if (isEditing) {
            formData["id"] = pluginFieldData["id"];
        }
        props.onUpdate("trigger", formData);
    }

    const handleFieldSettingsFormChanges = (formData) => {
        console.log("[ColumnPopupFieldsManager] handleFieldSettingsFormChanges : ", formData);
        props.onUpdate("columnSettings", formData);
    }

    console.log("render: columnSettingsFieldData = ", columnSettingsFieldData, "; pluginFieldData = ", pluginFieldData);

    if (isColumnDetailsLoading) {
        return(<Skeleton active />)
    } else {
        return (<span>
            <ColumnSettingsForm
                tableId={tableId}
                triggerData={columnSettingsFieldData}
                fieldSchema={columnTypeDetails != undefined && columnTypeDetails.hasOwnProperty("column_fields") ? columnTypeDetails["column_fields"] : []}
                columnTypeId={columnTypeId}
                onFormUpdate={handleFieldSettingsFormChanges}
            />
            <NewTriggerForm
                tableId={tableId}
                triggerData={pluginFieldData}
                fieldSchema={columnTypeDetails != undefined && columnTypeDetails.hasOwnProperty("plugin_fields") ? columnTypeDetails["plugin_fields"] : []}
                columnId={accountMetaColumnId}
                columnTypeId={columnTypeId}
                onFormUpdate={handleTriggerFormChanges}
            />
        </span>);
    }


};

export default ColumnPopupFieldsManager;

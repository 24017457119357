function getRandomId(length) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charsLength = 62; // We're using a fixed length for the known charset.
    let result = '';

    while (length--) {
        result += chars[(Math.random() * charsLength) | 0];
    }

    return result;
}


function triggerDataToFormSerialization(apiData) {
    let formData = {
        "id": apiData["id"],
        "init_event_type": apiData["rule_init_type"],
        "init_rule_column": apiData["init_rule_column"],
        "init_rule_condition": apiData["rule_init_condition"],
        "init_rule_value": apiData["rule_init_value"],
        //plugin values
    };

    for (let i = 0; i < apiData["effect_list"].length; i++) {
        const selectedEffect = apiData["effect_list"][i];
        const pluginFieldObj = selectedEffect["field_value_dict"];
        const pluginFieldKeyList = Object.keys(pluginFieldObj);

        for (let j = 0; j < pluginFieldKeyList.length; j++) {
            formData[pluginFieldKeyList[j]] = pluginFieldObj[pluginFieldKeyList[j]];
        }
    }

    return formData;
}


export {
    getRandomId,
    triggerDataToFormSerialization
}

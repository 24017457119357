import {ENV_ACCOUNT_API_HOST} from "../config";
import {useNotification} from './NotificationContext';
import {useApi} from "./requests";

const usePublicApi = () => {
    const notification = useNotification();
    const {metaRequest} = useApi();

    const postSignup = async (postData) => {
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/signup/`;
        try {
            const response = await fetch(requestUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData)
            });
            const data = await response.json();

            if (!response.ok) {
                let errValues = Object.values(data);
                for (let i = 0; i < errValues.length; i++) {
                    notification.error({
                        message: `Error`,
                        description: errValues[i][0],
                        placement: "bottomLeft",
                    });
                }
            }

            return [response.ok, data];
        } catch (error) {
            notification.error({
                message: 'Network Error',
                description: error.message || 'Cannot connect to the server',
                placement: 'bottomLeft',
            });

            return [false, {error: error.message}];
        }
    };



    const postSignin = async (postData) => {
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/login/`;
        try {
            const response = await fetch(requestUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData)
            });
            const data = await response.json();

            if (!response.ok) {
                let errValues = Object.values(data);
                for (let i = 0; i < errValues.length; i++) {
                    notification.error({
                        message: `Error`,
                        description: errValues[i][0],
                        placement: "bottomLeft",
                    });
                }
            }

            return [response.ok, data];
        } catch (error) {
            notification.error({
                message: 'Network Error',
                description: error.message || 'Cannot connect to the server',
                placement: 'bottomLeft',
            });

            return [false, {error: error.message}];
        }
    };




    const postResetPassword = async (postData) => {
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/password-reset/`;
        try {
            const response = await fetch(requestUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData)
            });
            const data = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: data["error"],
                    placement: "bottomLeft",
                });
            }

            const statusCode = response.status;
            return {statusCode, data}
        } catch (error) {
            notification.error({
                message: 'Network Error',
                description: error.message || 'Cannot connect to the server',
                placement: 'bottomLeft',
            });

            const statusCode = 500;
            const data = {error: error.message};
            return {statusCode, data}
        }
    };




    const postConfirmResetPassword = async (postData) => {
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/password-reset/confirm/`;
        try {
            const response = await fetch(requestUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData)
            });
            const data = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: data["error"],
                    placement: "bottomLeft",
                });
            }

            const statusCode = response.status;
            return {statusCode, data}
        } catch (error) {
            notification.error({
                message: 'Network Error',
                description: error.message || 'Cannot connect to the server',
                placement: 'bottomLeft',
            });

            const statusCode = 500;
            const data = {error: error.message};
            return {statusCode, data}
        }
    };


    const getLanguageListRequest = async () => {
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/language/`

        const params = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        return await metaRequest(requestUrl, params);
    }



    return {
        postSignup,
        postSignin,
        postResetPassword,
        postConfirmResetPassword,
        getLanguageListRequest
    };
};

export default usePublicApi;

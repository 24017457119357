import {Form, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "../utils/requests";


const { TextArea } = Input;


const InterfaceSettingsSideControl = (props) => {
    const {interfaceId, tableId, initValues} = props;
    const {getWorkflowListRequest} = useApi();

    const [form] = Form.useForm();

    const [isWorkflowListLoading, setWorkflowListLoading] = useState(false);
    const [workflowList, setWorkflowList] = useState([]);

    const handleWorkflowRequest = async () => {
        setWorkflowListLoading(true);
        const {isOk, responseData} = await getWorkflowListRequest(tableId);
        console.log("handleWorkflowRequest: responseData = ", responseData);
        let formWorkflowList = [];
        if (responseData && responseData.length > 0) {
            for (let i = 0; i < responseData.length; i++) {
                formWorkflowList.push({
                    "value": responseData[i].id.toString(),
                    "label": responseData[i].name,
                });

                // if (i == 0) {
                //     form.setFieldValue("workflow_connected_button", responseData[i].id.toString());
                // }
            }

        }

        setWorkflowList(formWorkflowList);
        setWorkflowListLoading(false);
    }

    useEffect(() => {
        handleWorkflowRequest();
    }, [interfaceId, tableId]);


    const handleFormChanges = (changedValues, values) => {
        props.onUpdate(values);
    }

    return(<Form
        layout="vertical"
        form={form}
        initialValues={initValues}
        onValuesChange={handleFormChanges}
    >
        <div style={{paddingRight: "7px", paddingLeft: "7px", position: "relative", height: "100%"}}>

            <Form.Item name="form_name" label={`Form name:`}>
                <Input />
            </Form.Item>


            <Form.Item name="form_description"  label={`Form description:`}>
                <TextArea autoSize />
            </Form.Item>


            <Form.Item name="workflow_button_text"  label={`Workflow Button Text:`}>
                <Input />
            </Form.Item>


            <Form.Item name="workflow_connected_button"  label={`Workflow:`}>
                <Select loading={isWorkflowListLoading} disabled={isWorkflowListLoading} options={workflowList} />
            </Form.Item>

            {/*<div style={{*/}
            {/*    // position: "absolute",  bottom: 0,*/}
            {/*    width: "100%", backgroundColor: "#fff", borderTop: "1px solid #f0f0f0"}}>*/}
            {/*    <Form.Item>*/}
            {/*        /!*loading={isLoading}*!/*/}
            {/*        <Button type="primary" htmlType="submit" >*/}
            {/*            Update*/}
            {/*        </Button>*/}
            {/*    </Form.Item>*/}

            {/*    <Form.Item>*/}
            {/*        /!*loading={isLoading}*!/*/}
            {/*        <Button type="primary" htmlType="submit" >*/}
            {/*            Publish this version*/}
            {/*        </Button>*/}
            {/*    </Form.Item>*/}
            {/*</div>*/}

        </div>
    </Form>)
}


export default InterfaceSettingsSideControl;

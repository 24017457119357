
import React, { createContext, useContext, useState } from 'react';

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
    const [requestCounts, setRequestCounts] = useState({});

    const addRequest = (id) => {
        setRequestCounts(prevCounts => ({
            ...prevCounts,
            [id]: (prevCounts[id] || 0) + 1
        }));
    };

    const removeRequest = (id) => {
        setRequestCounts(prevCounts => ({
            ...prevCounts,
            [id]: Math.max((prevCounts[id] || 1) - 1, 0)
        }));
    };

    const isLoading = (id) => {
        return (requestCounts[id] || 0) > 0;
    };

    return (
        <LoaderContext.Provider value={{ addRequest, removeRequest, isLoading }}>
            {children}
        </LoaderContext.Provider>
    );
};

import {putCellValueTableRequest} from "./requests";

const columnRegexPattern = /column:(\d+)/;


const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}


const handleCellValueChanged = async (tableId, rowId, changedValue) => {
    // setIsBackgroundLoading(true);
    // addRequest(tableId);

    const objectKeyList = Object.keys(changedValue)
    const valueKey = objectKeyList[0];

    const matchResult = valueKey.match(columnRegexPattern);

    if (matchResult) {
        // The first capturing group (the digits) will be at index 1
        const columnId = matchResult[1];
        console.log("Column ID:", columnId);

        console.log("handleCellValueChanged = ", changedValue);

        let content = null;
        if(changedValue[valueKey].file != undefined) {
            const fileData = changedValue[valueKey].file.response;

            content = {
                "d": fileData.filename,
                "m": {
                    "ldg": false,
                    "dsbl": null,
                    "d": {
                        "path": fileData.path,
                        "public_path": fileData.public_path,
                        "size": fileData.size,
                    }
                }
            }
        } else {
            content = {"d": changedValue[valueKey], "m": null}
        }

        await putCellValueTableRequest(tableId, rowId, columnId, content);

    }

    // removeRequest(tableId);
    // setIsBackgroundLoading(false);
}

const debouncedOnEdit = debounce((tableId, rowId, changedValue) => {
    handleCellValueChanged(tableId, rowId, changedValue);
}, 300);



export {debounce, debouncedOnEdit, columnRegexPattern}

import usePublicApi from "../utils/publicRequests";
import {useApi} from "../utils/requests";
import {useUser} from "../utils/UserProvider";
import {Button, Col, Divider, Form, Row, Select, Skeleton} from "antd";
import React, {useEffect, useState} from "react";
import ApiKeyManagementTable from "../features/ApiKeyManagementTable";

function CompanySettingsView(props) {
    const {getLanguageListRequest} = usePublicApi();
    const {updateProfileRequest} = useApi();
    const { user , updateUser } = useUser();

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [isFormSaving, setIsFormSaving] = useState(false);

    const [languageList, setLanguageList] = useState([]);
    const [formInitValues, setFormInitValues] = useState({});


    useEffect( () => { }, []);


    if(isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>);
    }

    return(<div style={{background: "#fff", paddingLeft: "20px", paddingRight: "20px"}}>
        <Row>
            <Col span={24}>
                <h2>Company settings</h2>
                <Divider />

                <Row>
                    <Col span={17}>
                        <ApiKeyManagementTable />
                    </Col>
                </Row>


            </Col>
        </Row>
    </div>)
}


export default CompanySettingsView;

import {Button, Col, Divider, Form, Input, Modal, Row, Select, Skeleton, Spin, Table, Typography} from "antd";
import {useApi} from "../utils/requests";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const {Paragraph} = Typography;


function WorkflowControlCell(props) {
    const {record} = props;
    const {deleteWorkflowRequest} = useApi();

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async () => {
        setIsDeleting(true);
        await deleteWorkflowRequest(record.id);
        props.onUpdate();
        setIsDeleting(false);
    }

    return (<div>
        <Button disabled={record["last_version_id"] == record["published_version_id"]}>Publish</Button>&nbsp;
        <Button>Recalculate</Button>&nbsp;
        <Button danger onClick={handleDelete} loading={isDeleting}>Remove</Button>
    </div>)
}

function WorkflowTitleCell(props) {
    const {initValue, key, workflowId} = props;
    const {putWorkflowNameRequest} = useApi();

    const [text, setText] = useState("");

    useEffect(() => {
        setText(initValue);
    }, [key]);

    const handleTextChange = async (newText) => {
        console.log("handleTextChange", newText);

        if (newText != text) {
            setText(newText);
            await putWorkflowNameRequest(workflowId, newText);
        }
    }

    return (
        <Paragraph
            editable={{
                tooltip: 'click to edit text',
                onChange: handleTextChange,
                triggerType: ['icon', 'text'],
            }}
            style={{margin: 0}}
        >
            {text}
        </Paragraph>
    )
}


function WorkflowCreationButton(props) {
    const [form] = Form.useForm();

    const {postWorkflowRequest, getTableListRequest, getTableColumnListRequest} = useApi();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isTableDataLoading, setIsTableDataLoading] = useState(true);
    const [isColumnDataLoading, setIsColumnDataLoading] = useState(false);
    const [isWorkflowCreation, setIsWorkflowCreation] = useState(false);

    const [tableList, setTableList] = useState([]);
    const [columnList, setColumnList] = useState([]);


    const handleNewWorkflow = async (values) => {
        setIsWorkflowCreation(true);
        const {isOk, responseData} = await postWorkflowRequest(values);
        if (isOk) {
            props.onUpdate();
        }
        setIsWorkflowCreation(false);
    }


    const handleTableChange = (key) => {
        console.log("handleTableChange", key);
        columnListLoading(key);
    }

    async function columnListLoading(selectedTableId) {
        setIsColumnDataLoading(true);
        const apiColumnListResponse = await getTableColumnListRequest(selectedTableId);
        const apiColumnList = apiColumnListResponse["column_list"];

        let formColumnList = [];
        let selectedColumnId = null;

        for (let i = 0; i < apiColumnList.length; i++) {
            formColumnList.push({
                "value": apiColumnList[i].id,
                "label": apiColumnList[i].name,
            })

            if (i == 0) {
                selectedColumnId = apiColumnList[i].id;
            }
        }

        setColumnList(formColumnList);
        form.setFieldValue("column", selectedColumnId);
        setIsColumnDataLoading(false);
    }

    const handleLoadFormData = async () => {
        const apiTableList = await getTableListRequest();
        let formTableList = [];
        let selectedTableId = null;
        for (let i = 0; i < apiTableList.length; i++) {
            formTableList.push({
                "value": apiTableList[i].id,
                "label": apiTableList[i].name,
            })

            if (i == 0) {
                selectedTableId = apiTableList[i].id;
            }
        }

        setTableList(formTableList);
        form.setFieldValue("table", selectedTableId);

        console.log(" selectedTableId = ", selectedTableId);
        if (selectedTableId != null) {
            await columnListLoading(selectedTableId);
        }

        console.log("handleLoadFormData = ", apiTableList);
        setIsTableDataLoading(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }


    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("[handleOk] value = ", values);

                let workflowName = null;
                if (values["name"] != null && values["name"].length > 0) {
                    workflowName = values["name"];
                }

                const formValues = {
                    "workflow_name": workflowName,
                    "table_id": values.table,
                    "column_id": values.column,
                };

                handleNewWorkflow(formValues);

            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }

    const handleOpen = () => {
        setIsModalOpen(true);
        handleLoadFormData();
    }

    return (<span>
        <Button onClick={handleOpen} style={{marginTop: "12px"}}>Create a new</Button>
        <Modal
            title="Create a new workflow"
            okButtonProps={{
                disabled: isTableDataLoading || isColumnDataLoading,
                loading: isWorkflowCreation
            }}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item name={"name"} label={"Name:"}>
                    <Input/>
                </Form.Item>

                <Form.Item name={"table"} label={"Table:"}>
                    <Select options={tableList} onChange={handleTableChange} loading={isTableDataLoading}
                            disabled={isTableDataLoading}/>
                </Form.Item>

                 <Form.Item name={"column"} label={"Column:"}>
                    <Select options={columnList} loading={isTableDataLoading || isColumnDataLoading}
                            disabled={isTableDataLoading || isColumnDataLoading}/>
                </Form.Item>

            </Form>
        </Modal>
    </span>)
}

function WorkflowView(props) {
    const [workflowList, setWorkflowList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const {getWorkflowListRequest} = useApi();

    const handleWorkflowList = async () => {
        const {isOk, responseData} = await getWorkflowListRequest();
        console.log("getWorkflowListRequest = ", isOk, responseData);
        setIsLoading(false);
        setWorkflowList(responseData);
    }

    const handleTableUpdateFromTable = async () => {
        setIsLoading(true);
        await handleWorkflowList();
    }

    useEffect(() => {
        handleWorkflowList();
    }, [])


    const columns = [
        {
            title: 'Workflow',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            render: (text, record) => <WorkflowTitleCell key={record.id} workflowId={record.id} initValue={text}/>,
        },
        {
            title: 'Table',
            dataIndex: 'table_name',
            key: 'table_name',
            render: (text, record) => <Link to={`/table/${record["table"]["id"]}`}>{record["table"]["name"]}</Link>,
        },
        {
            title: 'Workflow',
            dataIndex: 'workflow_path',
            key: 'workflow_path',
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: 350,
            render: (text, record) => (<WorkflowControlCell onUpdate={handleTableUpdateFromTable} key={record.id} workflowId={record.id} record={record}/>),
        },
    ];


    if (isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>);
    }


    return (<div style={{background: "#fff", paddingLeft: "20px", paddingRight: "20px"}}>
        <Row>
            <Col span={20}>
                <h2>Workflows</h2>
            </Col>
            <Col span={4}>
                <WorkflowCreationButton onUpdate={handleTableUpdateFromTable}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table dataSource={workflowList} columns={columns}/>
            </Col>
        </Row>
    </div>)
}


export default WorkflowView;

import {Col, Divider, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {useApi} from "../utils/requests";
import PluginSettingsView from "../features/PluginSettingsView";


function SettingsView(props) {
    const {getInstalledPluginListRequest} = useApi();

    const [isPluginLoading, setIsPluginLoading] = useState(false);
    const [pluginList, setPluginList] = useState([]);

    const gettingPlugins = async () => {
        setIsPluginLoading(true);
        const newPluginList = await getInstalledPluginListRequest();
        setPluginList(newPluginList["plugin_list"]);
        setIsPluginLoading(false);
    }

    useEffect( () => {
        gettingPlugins();
    }, []);


    let pluginRenderList = []
    for (let i = 0; i < pluginList.length; i++) {
        pluginRenderList.push(<div>
            <Row>
                <Col span={24}>
                    <h2>{pluginList[i]["name"]}</h2>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <PluginSettingsView accountPluginId={pluginList[i]["account_plugin_id"]} />
                </Col>
            </Row>
        </div>)
    }
    
    
    return(<div style={{background: "#fff", paddingLeft: "20px", paddingRight: "20px"}}>
        <Row>
            <Col span={24}>
                <h2>Installed plugins</h2>
                <Divider />

                {isPluginLoading ? <Spin /> : null}

                {pluginRenderList}

            </Col>
        </Row>
    </div>)
}


export default SettingsView;

// Create a context for the user
import {createContext, useContext, useState} from "react";
import {useTheme} from "./ThemeProvider";

const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const {toggleDirection} = useTheme();

    // Function to update the user
    const updateUser = (userData) => {
        if(userData != null && userData["preference"] != null) {
            const isRtl = userData["preference"]["language"]["rtl"]
            toggleDirection(isRtl ? "rtl" : "ltr");
        }
        setUser(userData);
    };

    // Function to clear the user (logout)
    const clearUser = () => {
        setUser(null);
    };

    return (
        <UserContext.Provider value={{ user, updateUser, clearUser }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the user context
export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

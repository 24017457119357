import React, {useEffect} from 'react';
import {Layout, Row, Col, Dropdown, Menu, Skeleton, Typography} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faPlus,
    faSignOutAlt,
    faUser,
    faCog,
    faPalette,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
import {Link, useNavigate} from "react-router-dom";
import {useApi} from "../utils/requests";
import '../appStyles.css'; // Ensure this path is correct to your CSS file

const {Text} = Typography;


function WorkspaceDropdown(props) {
    const navigate = useNavigate();

    const [isWorkspaceCreation, setIsWorkspaceCreation] = React.useState(false);
    const [isWorkspaceListLoading, setIsWorkspaceListLoading] = React.useState(false);

    const [workspaceList, setWorkspaceList] = React.useState([]);
    const [workspaceRenderList, setWorkspaceRenderList] = React.useState(<Skeleton active={true}/>);


    useEffect(() => {
        handleGetWorkspaceList();
    }, [])

    const {postCreateNewWorkspaceRequest, getWorkspaceRequest} = useApi();

    const handleCreateNew = async () => {
        setIsWorkspaceCreation(true);
        await postCreateNewWorkspaceRequest();
        await handleGetWorkspaceList();
        setIsWorkspaceCreation(false);
    }

    const handleChangeWorkspace = (workspaceId) => {
        console.log("handleChangeWorkspace: ", workspaceId)
        props.onSelectUpdate(workspaceId);
    }
    const handleGetWorkspaceList = async () => {
        setIsWorkspaceListLoading(true);
        const dataResponse = await getWorkspaceRequest();
        const apiWorkspaceList = dataResponse["data"];

        let localWorkspaceRenderList = [];
        for (let i = 0; i < apiWorkspaceList.length; i++) {
            localWorkspaceRenderList.push(<Menu.Item key={apiWorkspaceList[i]["id"]}>

                <Link onClick={() => handleChangeWorkspace(apiWorkspaceList[i]["id"])}>
                    <Row align="middle">
                        <Col>
                            <div style={{
                                width: "32px",
                                height: "32px",
                                background: "#fff",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <div
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50px",
                                        background: "#3479F6"
                                    }}>&nbsp;
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginLeft: "8px"}}>{apiWorkspaceList[i]["name"]}</Col>
                    </Row>
                </Link>
            </Menu.Item>)
        }

        setWorkspaceRenderList(localWorkspaceRenderList)
        setIsWorkspaceListLoading(false);
    }


    const workspaceDropdownList = (

        <Menu>
            <span>
                {workspaceRenderList}
            </span>


            <Menu.Divider/>

            {isWorkspaceCreation ? <Menu.Item key="new-workspace" disabled={true}>
                    <Link disabled={true}><FontAwesomeIcon icon={faSpinner}/> Additing new workspace ... </Link>
                </Menu.Item> :
                <Menu.Item key="new-workspace">
                    <Link onClick={(e) => {
                        e.preventDefault();
                        handleCreateNew()
                    }}><FontAwesomeIcon icon={faPlus}/> Add new workspace</Link>
                </Menu.Item>}


            <Menu.Item key="3">

                <Link href="#"><FontAwesomeIcon icon={faUser}/> Account settings</Link>

            </Menu.Item>

            <Menu.Item key="4">

                <Link href="#"><FontAwesomeIcon icon={faCog}/> Workspace settings</Link>

            </Menu.Item>

            <Menu.SubMenu key="5" title="Theme">

                <Menu.Item key="5-1">

                    <Link href="#">System</Link>

                </Menu.Item>

                <Menu.Item key="5-2">

                    <Link href="#">Light</Link>

                </Menu.Item>

                <Menu.Item key="5-3">

                    <Link href="#">Dark</Link>

                </Menu.Item>

            </Menu.SubMenu>

            <Menu.Divider/>

            <Menu.Item key="6">

                <Link href="#"><FontAwesomeIcon icon={faSignOutAlt}/> Sign out</Link>

            </Menu.Item>

        </Menu>

    );

    return (
        <div>

            <Dropdown overlay={workspaceDropdownList} trigger={['click']} disabled={true}>
                <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <Col style={{padding: "10px 10px 10px 7px"}}>

                        <div style={{
                            width: "32px",
                            height: "32px",
                            background: "#fff",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>

                            <div style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50px",
                                background: "#3479F6"
                            }}></div>

                        </div>

                    </Col>

                    <Col span={17} style={{display: "flex", alignItems: "center", fontSize: "11pt"}}>

                        <Text disabled>
                            {/*<a onClick={e => e.preventDefault()} >*/}
                            <span style={{color: "rgb(122 122 122)"}}>
                                Workspace <span style={{position: "absolute", right: "8px"}}>
                                {/*<FontAwesomeIcon icon={faChevronDown}/>*/}
                            </span>
                            </span>
                            {/*</a>*/}
                        </Text>


                    </Col>

                </Row>
            </Dropdown>
        </div>
    );

}


export default WorkspaceDropdown;

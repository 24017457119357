// const ENV_API_HOST = "http://127.0.0.1:5000";
// const ENV_ACCOUNT_API_HOST = "http://localhost:8080";
// const PLUGIN_API_HOST = "http://127.0.0.1:8000";
// const TABLE_UPDATE_FREQUENCY = 300000

const ENV_API_HOST = "https://api-platform.elasticflow.app";
const PLUGIN_API_HOST = "https://exts.elasticflow.app";
const ENV_ACCOUNT_API_HOST = "https://api-auth.elasticflow.app";
const TABLE_UPDATE_FREQUENCY = 7000;


export {ENV_API_HOST, TABLE_UPDATE_FREQUENCY, PLUGIN_API_HOST, ENV_ACCOUNT_API_HOST};

import {Col, Divider, Form, Row, Spin, Input, Checkbox, Button, Card, notification} from "antd";
import "../unauth.css";
import usePublicApi from "../utils/publicRequests";
import {Link, useNavigate} from "react-router-dom";
// import {postSignup} from "../utils/publicRequests";

function SignupView(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {postSignup} = usePublicApi();

    const handleSignupRequest = async (postData) => {
        const responseData = await postSignup(postData);
        const isOk = responseData[0];
        const response = responseData[1];

        if (isOk) {
            console.log("setup jwt")
            localStorage.setItem('jwtToken', response["access"]);
            navigate("/");
        }

    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);

        const postData = {
            "email": values.email,
            "password": values.password
        };

        handleSignupRequest(postData);

    };

    return (
        <div style={{
            width: '100%',
            background: "#f5f5f5",
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Card title="Sign up" style={{width: "400px"}}>

                <Form
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input size={"large"} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password size={"large"} placeholder="Password"/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password size={"large"} placeholder="Confirm Password"/>
                    </Form.Item>

                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                            },
                        ]}
                    >
                        <Checkbox>
                            I have read the <a href="">agreement</a>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Register
                        </Button>
                    </Form.Item>
                </Form>
                <Row>
                    <Col span={24}>Already has an account? <Link to={"/signin"}>Sign in</Link></Col>
                </Row>
            </Card>

        </div>
    );
}


export default SignupView;

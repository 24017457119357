import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Row,
    Skeleton,
    Space,
    Spin,
    Tabs,
    Typography
} from "antd";
import {DownOutlined, LoadingOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import {putCellValueTableRequest, useApi} from "../utils/requests";
import InterfaceForm from "../components/InterfaceForm";
import InterfaceFormPreview from "../components/InterfaceForm";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InterfaceFieldsSideControl from "../features/InterfaceFieldsSideControl";
import InterfaceSettingsSideControl from "../features/InterfaceSettingsSideControl";
import {columnRegexPattern} from "../utils/InterfaceUtils";


function InterfaceView(props) {
    let {interfaceId} = useParams();
    const {getSelectedInterfaceRequest, getTableListRequest,
        putInterfaceSourceTableRequest, putInterfaceVersionFieldsChangesRequest,
        postInterfaceVersionPublishRequest, postInterfaceVersionCreateNewRowRequest,
        putInterfaceVersionChangesSettingsRequest,
        getDataListRequest
    } = useApi();

    const [tableId, setTableId] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isTableUpdating, setIsTableUpdating] = useState(false);


    const [interfaceData, setInterfaceData] = useState(null);
    const [tableList, setTableList] = useState([]);
    const [fieldsFormData, setFieldsFormData] = useState({});
    const [settingFormData, setSettingFormData] = useState({});
    const [workflowsFormData, setWorkflowsFormData] = useState([]);

    const [updateId, setUpdateId] = useState(null);
    const [frontendRowId, setFrontendRowId] = useState(null);

    const [selectedTabKey, setSelectedTabKey] = useState("fields");

    const [rowData, setRowData] = useState(null);
    const [rawRowData, setRawRowData] = useState(null);


    useEffect(() => {
        getSelectedInterface();
    }, [interfaceId]);




    const getRowData = async (tableId, frontendRowId) => {
        const {isOk, responseData} = await getDataListRequest(tableId, frontendRowId);
        return responseData[0]
    }

    const getSelectedInterface = async () => {
        setIsLoading(true);
        const tableList = await getTableListRequest();
        console.log("tableList = ", tableList);

        let formTableList = [];
        for (let i = 0; i < tableList.length; i++) {
            formTableList.push({
                "key": tableList[i].id.toString(),
                "label": tableList[i].name,
            });
        }

        const {isOk, responseData} = await getSelectedInterfaceRequest(interfaceId);
        setFieldsFormData(responseData["version"]["value_map"]);
        setTableList(formTableList);
        setInterfaceData(responseData);

        const versionId = responseData["version"]["id"];
        let currentFrontendRowId = responseData["version"]["empty_frontend_row_id"];
        if (currentFrontendRowId == null) {
            const {responseStatus, responseData } = await postInterfaceVersionCreateNewRowRequest(versionId);
            setFrontendRowId(responseData["frontend_row_id"]);
            currentFrontendRowId = responseData["frontend_row_id"];
        } else {
            setFrontendRowId(currentFrontendRowId);
        }

        const tableId = responseData["account_meta_table"]["id"]

        const responseRowDataMap = await getRowData(tableId, currentFrontendRowId);

        let rowDataMap = {}
        let rowDataKeyList = Object.keys(responseRowDataMap);

        for (let i = 0; i < rowDataKeyList.length; i++) {
            const columnKey = `column:${rowDataKeyList[i]}`
            rowDataMap[columnKey] = responseRowDataMap[rowDataKeyList[i]]["d"];
        }

        setRowData(rowDataMap);
        setRawRowData(responseRowDataMap);
        setTableId(tableId);


        let apiSettingsFormData = responseData["version"]["config"];

        if (responseData["version"]["workflows"].length > 0) {
            const selectedWorkflow = responseData["version"]["workflows"][0];
            apiSettingsFormData["workflow_button_text"] = selectedWorkflow["field_name"];
            apiSettingsFormData["workflow_connected_button"] = selectedWorkflow["account_workflow_id"].toString();
        }

        setWorkflowsFormData(responseData["version"]["workflows"]);
        setSettingFormData(apiSettingsFormData);
        setIsLoading(false);
    }


    if (isLoading || interfaceData == null) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>)
    }

    const handleControlDataUpdate = (values) => {
        setFieldsFormData(values);
        setUpdateId(Math.random());
    }


    const handleControlSettingsUpdate = (values) => {
        setSettingFormData(values);
        setUpdateId(Math.random());
    }

    const putTableChange = async (tableId) => {
        setIsTableUpdating(true);
        const {responseStatus, responseData} = await putInterfaceSourceTableRequest(interfaceId, tableId);

        if (responseStatus) {
            const {isOk, responseData} = await getSelectedInterfaceRequest(interfaceId);
            setInterfaceData(responseData);
            setFieldsFormData(responseData["version"]["value_map"]);
            setUpdateId(Math.random());
        }
        setIsTableUpdating(false);
    }

    const handleChangeInterfaceTable = (event) => {
        console.log("handleChangeInterfaceTable = ", event.key);
        putTableChange(event.key);
    }


    const handleUpdateSideForm = async () => {
        console.log("[InterfaceView] handleUpdateSideForm = ", fieldsFormData);
        await putInterfaceVersionFieldsChangesRequest(interfaceId, fieldsFormData);
    }

    const handleUpdateSettingsSideForm = async () => {
        console.log("[InterfaceView] handleUpdateSettingsSideForm = ", settingFormData);
        await putInterfaceVersionChangesSettingsRequest(interfaceId, settingFormData);
    }

    const handlePublishForm = async () => {
        console.log("[InterfaceView] handlePublishForm = ");
        await postInterfaceVersionPublishRequest(interfaceId);
    }


    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }



    const handleCellValueChanged = async (tableId, rowId, changedValue) => {
        // setIsBackgroundLoading(true);
        // addRequest(tableId);

        const objectKeyList = Object.keys(changedValue)
        const valueKey = objectKeyList[0];

        const matchResult = valueKey.match(columnRegexPattern);

        if (matchResult) {
            // The first capturing group (the digits) will be at index 1
            const columnId = matchResult[1];
            console.log("Column ID:", columnId);

            console.log("handleCellValueChanged = ", changedValue);

            let content = null;
            if(changedValue[valueKey].file != undefined) {
                const fileData = changedValue[valueKey].file.response;

                content = {
                    "d": fileData.filename,
                    "m": {
                        "ldg": false,
                        "dsbl": null,
                        "d": {
                            "path": fileData.path,
                            "public_path": fileData.public_path,
                            "size": fileData.size,
                        }
                    }
                }
            } else {
                content = {"d": changedValue[valueKey], "m": null}
            }

            await putCellValueTableRequest(tableId, rowId, columnId, content);

        }

        // removeRequest(tableId);
        // setIsBackgroundLoading(false);
    }

    const debouncedOnEdit = debounce((tableId, rowId, changedValue) => {
        handleCellValueChanged(tableId, rowId, changedValue);
    }, 300);

    const onFormValueChanges = (value) => {
        console.log("[InterfaceView] onFormValueChanges = ", value);
        const tableId = interfaceData["account_meta_table"]["id"];
        debouncedOnEdit(tableId, frontendRowId, value);
    }
    
    
    const onFormFileChanges = (columnId, fileData) => {
        console.log("[InterfaceView] onFormFileChanges = ", fileData);
        const cellValue = {
            "d": fileData.filename,
            "m": {
                "ldg": false,
                "dsbl": null,
                "d": {
                    "path": fileData.path,
                    "public_path": fileData.public_path,
                    "size": fileData.size,
                }
            }
        }

        const tableId = interfaceData["account_meta_table"]["id"];

        // putCellValueTableRequest(tableId, frontendRowId, columnId, cellValue);

        // onFormValueChanges(cellValue);
    }

    const handleChangeSettingsTab = (value) => {
        console.log("handleChangeSettingsTab = ", value);
        setSelectedTabKey(value);
    }


    let sideComponentRender = <InterfaceFieldsSideControl
        isTableUpdating={isTableUpdating}
        interfaceId={interfaceData["id"]}
        interfaceData={interfaceData}
        onUpdate={handleControlDataUpdate}
    />;

    if (selectedTabKey == "settings") {
        sideComponentRender = <InterfaceSettingsSideControl
            initValues={settingFormData}
            interfaceId={interfaceData["id"]}
            tableId={tableId}
            onUpdate={handleControlSettingsUpdate}
        />;
    }


    let tableSelectorRender = (<Dropdown
        menu={{
            items: tableList,
            selectable: true,
            defaultSelectedKeys: [interfaceData["account_meta_table"]["id"].toString()],
            onSelect: handleChangeInterfaceTable,
        }}
        trigger={['click']}
    >
        <Link onClick={(e) => e.preventDefault()} style={{fontSize: "18px"}}>
            <Space>
                {interfaceData["account_meta_table"]["name"]}
                <span style={{fontSize: "16px"}}>
                                    <FontAwesomeIcon icon={faChevronDown}/>
                                </span>
            </Space>
        </Link>
    </Dropdown>);

    if (isTableUpdating) {
        tableSelectorRender = (<div>
            <Spin indicator={<LoadingOutlined style={{fontSize: 18}} spin/>}/>&nbsp;&nbsp; <span style={{fontSize: 18}}>Table is updating ...</span>
        </div>);
    }

    return (<div style={{backgroundColor: "#fff"}}>
        <Row>
            <Col span={18} style={{borderRight: "1px solid #f0f0f0"}}>
                <div style={{textAlign: "center"}}>
                <Dropdown
                        menu={{
                            items: [],
                            selectable: true,
                            defaultSelectedKeys: [],
                        }}
                        trigger={['click']}
                    >
                        <Typography.Text style={{fontSize: "24px"}}>
                            <Space>
                                {interfaceData["account_plugin"]["name"]}
                                <Typography.Text style={{fontSize: "16px"}}>
                                    <FontAwesomeIcon icon={faChevronDown}/>
                                </Typography.Text>
                            </Space>
                        </Typography.Text>
                    </Dropdown>
                    <Divider style={{marginBottom: "0px"}}></Divider>
                </div>

                <div>
                    <InterfaceFormPreview
                        tableId={interfaceData["account_meta_table"]["id"]}
                        frontendRowId={frontendRowId}
                        updateId={updateId}
                        interfaceId={interfaceData["id"]}
                        interfaceData={interfaceData}
                        settingFormData={fieldsFormData}
                        formSettingFormData={settingFormData}
                        workflowsFormData={workflowsFormData}
                        rowData={rowData}
                        rawRowData={rawRowData}
                        onValuesUpdate={onFormValueChanges}
                        onFileUpdate={onFormFileChanges}
                    />
                </div>
            </Col>
            <Col span={6}>
                <div style={{textAlign: "center", paddingTop: "16px"}}>
                    {tableSelectorRender}
                    <Divider style={{marginBottom: "0px"}}></Divider>
                </div>
                <div>
                    <Tabs
                        defaultActiveKey={selectedTabKey}
                        tabBarExtraContent={{left: <span>&nbsp;&nbsp;&nbsp;</span>}}
                        items={[
                            {
                                key: 'settings',
                                label: 'Settings',
                            },
                            {
                                key: 'fields',
                                label: 'Fields',
                            }
                        ]}
                        onChange={handleChangeSettingsTab}
                    />
                </div>
                <div style={{marginTop: "10px"}}>
                    {sideComponentRender}
                </div>

                <div><Button onClick={handleUpdateSideForm}>Update FIELDS</Button></div>
                <div><Button onClick={handleUpdateSettingsSideForm}>Update SETTINGS</Button></div>
                <div><Button onClick={handlePublishForm}>Publish current version</Button></div>
            </Col>
        </Row>
    </div>)
}

export default InterfaceView;

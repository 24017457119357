import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {LoaderProvider} from "./utils/LoaderContext";
import NotificationsProvider from './utils/NotificationContext';
import {PostHogProvider} from 'posthog-js/react'
import {ThemeProvider} from './utils/ThemeProvider';
import {UserProvider} from "./utils/UserProvider";

const options = {
    api_host: "https://eu.posthog.com",
}

// const apiKey = "";
const apiKey = "phc_C16HmAkzPzPknb916kAu3aQo7PPFlZPWbd23zFQHX8l"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider>
        <UserProvider>
            <NotificationsProvider>
                <LoaderProvider>
                    {/*<React.StrictMode>*/}
                    {/*<PostHogProvider*/}
                    {/*    apiKey={apiKey}*/}
                    {/*    options={options}*/}
                    {/*>*/}
                    <App/>
                    {/*</PostHogProvider>*/}
                    {/*</React.StrictMode>*/}
                </LoaderProvider>
            </NotificationsProvider>
        </UserProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

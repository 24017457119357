import {Card, Col, Form, Input, Row, Skeleton, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";


const InterfaceFieldsSideControl = (props) => {
    const [form] = Form.useForm();

    const [collapsedMap, setCollapsedMap] = useState({});

    const {interfaceData, interfaceId, isTableUpdating} = props;

    useEffect(() => {
        const columnList = interfaceData["account_table_column_list"];

        const localCollapsedMap = {};
        for (let i = 0; i < columnList.length; i++) {
            localCollapsedMap[columnList[i]["id"]] = false;
        }

        setCollapsedMap(localCollapsedMap);

    }, [interfaceId]);


    const handleOpenSettings = (columnId) => {
        let clone= Object.assign({}, collapsedMap);
        clone[columnId] = !collapsedMap[columnId];
        setCollapsedMap(clone);
    }


    const columnList = interfaceData["account_table_column_list"]
    const pluginFieldSchemaMap = interfaceData["account_plugin"]["field_schema"]

    let formRenderList = [];
    for (let i = 0; i < columnList.length; i++) {
        const formSettingItemList = [];
        const frontendType = columnList[i]["frontend_type"];
        const pluginFieldSchemaList = pluginFieldSchemaMap[frontendType];
        if (pluginFieldSchemaList != undefined) {
            for (let j = 0; j < pluginFieldSchemaList.length; j++) {
                const frontendFieldType = pluginFieldSchemaList[j]["field_frontend_type"];
                let fieldRender = null;
                if (frontendFieldType == "INPUT") {
                    fieldRender = <Input />;
                } else {
                    fieldRender = <span>Not setup frontend logic for {frontendFieldType}</span>;
                }
                const formSettingItem = <Form.Item label={`${pluginFieldSchemaList[j]["field_name"]}:`} name={`column:${columnList[i]["id"]}:field:${pluginFieldSchemaList[j]["id"]}`} style={{margin: "10px 0px 4px 0px"}} >
                    {fieldRender}
                </Form.Item>;
                formSettingItemList.push(formSettingItem);
            }

            if (pluginFieldSchemaList.length == 0) {
                formSettingItemList.push(<span>No defined fields for this field type</span>);
            }
        } else {
            formSettingItemList.push(<div style={{marginTop: "10px"}}>No defined fields for this field type</div>);
        }

        const formItem = (<Card key={columnList[i]["id"]} style={{backgroundColor: "#fafafa", marginBottom: "10px"}}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={21}>
                            {columnList[i]["name"]}
                        </Col>
                        <Col span={3}>
                            <Form.Item name={`column:${columnList[i]["id"]}:is_enabled`} style={{margin: 0, padding: 0}}>
                                <Switch size="small"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col span={24}>
                            <Link onClick={() => handleOpenSettings(columnList[i]["id"])}>
                                Settings &nbsp;
                                <FontAwesomeIcon icon={(collapsedMap[columnList[i]["id"]] == true) ? faChevronUp : faChevronDown } />
                            </Link>
                        </Col>
                    </Row>

                    <Row style={{display: (collapsedMap[columnList[i]["id"]] == true) ? "block": "none"}}>
                        <Col span={24}>
                            {formSettingItemList}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>)

        formRenderList.push(formItem);

    }


    const handleFormChanges = (changedValues, values) => {
        console.log("[InterfaceView] handleFormChanges = ", values);
        props.onUpdate(values);
    }

    // const onSubmit = (values) => {
    //   console.log("[InterfaceView] onSubmit = ", values);
    //
    //
    // }

    if (isTableUpdating) {
        return(<div style={{padding: "0px 10px 10px 10px"}}>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
        </div>)
    }

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={interfaceData["version"]["value_map"]}
            // onFinish={onSubmit} // onFinish triggers when the form is submitted and validated successfully
            onValuesChange={handleFormChanges}
        >
            <div style={{paddingRight: "7px", paddingLeft: "7px", position: "relative", height: "100%"}}>

                {formRenderList}


                {/*<div style={{*/}
                {/*    // position: "absolute",  bottom: 0,*/}
                {/*    width: "100%", backgroundColor: "#fff", borderTop: "1px solid #f0f0f0"}}>*/}
                {/*    <Form.Item>*/}
                {/*        /!*loading={isLoading}*!/*/}
                {/*        <Button type="primary" htmlType="submit" >*/}
                {/*            Update*/}
                {/*        </Button>*/}
                {/*    </Form.Item>*/}

                {/*    <Form.Item>*/}
                {/*        /!*loading={isLoading}*!/*/}
                {/*        <Button type="primary" htmlType="submit" >*/}
                {/*            Publish this version*/}
                {/*        </Button>*/}
                {/*    </Form.Item>*/}
                {/*</div>*/}

            </div>
        </Form>
    )

}

export default InterfaceFieldsSideControl;

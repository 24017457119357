import './MentionList.css'

import React, {
    forwardRef, useEffect, useImperativeHandle,
    useState,
} from 'react'
import {Spin} from "antd";

export default forwardRef((props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = index => {
        const item = props.items[index]

        if (item) {
            console.log(" selectItem = ", item);
            props.command(item)
        }
    }

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
    }

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
        selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
                upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                downHandler()
                return true
            }

            if (event.key === 'Enter') {
                enterHandler()
                return true
            }

            return false
        },
    }))


    console.log("MentionList: props.items = ", props.items);

    if(props.items.length == 1 && props.items[0].id == "loading") {
        return (
            <div className="items">
                <div style={{padding: "10px"}}><Spin size="small" /> Loading ...</div>
            </div>);
    } else {
        return (
            <div className="items">
                {props.items.length
                    ? props.items.map((item, index) => (
                        <button
                            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
                            key={index}
                            onClick={() => selectItem(index)}
                        >
                            {item.label}
                        </button>
                    ))
                    : <div className="item">No result</div>
                }
            </div>
        )
    }



})

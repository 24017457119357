
import usePublicApi from "../utils/publicRequests";
import {useApi} from "../utils/requests";
import {useUser} from "../utils/UserProvider";
import {Alert, Button, Card, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, Skeleton, Table} from "antd";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {QuestionCircleOutlined} from "@ant-design/icons";

function ApiKeyControls(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {putApiKeyRequest, deleteApiKeyRequest} = useApi();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(props.record);
    }, [props?.record?.id])


    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("[handleOk] value = ", values);
                const apiKeyId = props.record.id;
                putApiKeyRequest(apiKeyId, values);
                props.onUpdate()
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }

    const handleDeleteSelectedApiKey = async () => {
        const apiKeyId = props.record.id;
        await deleteApiKeyRequest(apiKeyId);
        props.onUpdate();
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleOpenModal = () => {
      setIsModalOpen(true);
    }

    return(<span>

        <Modal
            title="Edit an API Key"
            okButtonProps={{
                // disabled: isTableDataLoading || isColumnDataLoading,
                // loading: isWorkflowCreation
            }}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item name={"name"} label={"Name:"}>
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>

        <Button onClick={handleOpenModal}>Edit</Button>
        &nbsp;
        <Popconfirm
            title="Delete an Api Key"
            description="Are you sure to delete selected api key?"
            onConfirm={handleDeleteSelectedApiKey}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
        <Button danger>Delete</Button>
        </Popconfirm>
    </span>)
}



function ApiKeyManagementTable(props) {
    const {createApiKeyRequest, getApiKeyListRequest} = useApi();
    const { user , updateUser } = useUser();

    // const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [isFormSaving, setIsFormSaving] = useState(false);

    const [apiKeyList, setApiKeyList] = useState([]);
    const [formInitValues, setFormInitValues] = useState({});

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // width: 250,
        },
        {
            title: 'API Key',
            dataIndex: 'api_key',
            key: 'api_key',
        },
        {
            title: 'Created time',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            // width: 350,
            render: (text, record) => (<ApiKeyControls onUpdate={handleTableUpdate} key={record.id} record={record}/>),
        },
    ];


    const handleTableUpdate = async () => {
        setIsLoading(true);
        await getRowList();
    }

    const getRowList = async () => {
        const {isOk, apiResponse} = await getApiKeyListRequest();
        setApiKeyList(apiResponse);
        setIsLoading(false);
    }


    useEffect( () => {
        getRowList();
    }, []);

    const handleNewApiKey = async () => {
        setIsCreating(true);
        await createApiKeyRequest();
        setIsLoading(true);
        await getRowList();
        setIsCreating(false);
    }


    if(isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>);
    }

    return(
        <div>
            <Row>
                <Col span={20}>
                    <h2>API Keys</h2>
                </Col>
                <Col span={4}>
                    <Button loading={isCreating} onClick={handleNewApiKey}>New API Key</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div style={{marginTop: "20px", marginBottom: "20px"}}>
                        <Alert message={<span>API keys provide access to the public API. <a
                            href={"https://api-public.elasticflow.app/api/doc"}
                            target={"_blank"}>Documentation</a></span>} type="info"/>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table columns={columns} dataSource={apiKeyList}  />
                    {/*{JSON.stringify(apiKeyList, null, 2)}*/}
                </Col>
            </Row>
        </div>
    )
}


export default ApiKeyManagementTable;

import DataEditor, {
    CompactSelection,
    DataEditorProps,
    GridCellKind,
    GridColumn,
    GridColumnIcon,
} from "@glideapps/glide-data-grid";
import {LinksCell, SpinnerCell } from "@glideapps/glide-data-grid-cells";
import "@glideapps/glide-data-grid/dist/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {tableData} from "../data";
import NewColumnPopup from "../features/NewColumnPopup";
import {useParams} from "react-router-dom";
import {Divider, Dropdown, Menu, Popover, Modal, Button, Spin, Row, Col, Popconfirm} from "antd";
import {ExclamationCircleFilled, LoadingOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {getRandomId} from "../utils/etc";
import ExampleCustomCell from "../utils/OurCells/ExampleCustomCell";
import dayjs from "dayjs";
import {
    getCellUpdatesForSelectedTableRequest,

    putColumnWidthSizeRequest, useApi
} from "../utils/requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import { useLoader } from '../utils/LoaderContext';
import {TABLE_UPDATE_FREQUENCY} from "../config";
import FileCustomCell from "../utils/OurCells/FileCustomCell";
import DropdownCell from "../utils/OurCells/DropdownCell";


const {confirm} = Modal;

const TableGrid = (props) => {
    const {typeList, tableId} = props;

    const {deleteRowListRequest, postRowRegenerationRequest, postCellRegenerationRequest} = useApi();

    const ref = React.useRef(null);

    const [selection, setSelection] = useState({
        columns: CompactSelection.empty(),
        rows: CompactSelection.empty(),
    });

    const [showSearch, setShowSearch] = useState(false);
    const onSearchClose = useCallback(() => setShowSearch(false), []);
    let [data, setData] = useState([]);
    let [dataMapIndexByFId, setDataMapIndexByFId] = useState({});

    let [isSubMenuOpen, setIsSubMenuOpen] = useState(true);
    // const cellProps = useExtraCells();
    const [showMenu, setShowMenu] = useState();
    const [columns, setColumns] = useState([]);
    const columnsRef = React.useRef([]);

    const [popoverVisible, setPopoverVisible] = useState(false);
    const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);

    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedCell, setSelectedCell] = useState(null);


    const [popoverBounds, setPopoverBounds] = useState({});

    const [selectedRowList, setSelectedRowList] = useState([]);
    const [lastUpdateDate, setLastUpdateDate] = useState(null);
    const lastUpdateDateRef = React.useRef(null);

    const { addRequest, removeRequest } = useLoader();


    useEffect(() => {
        const {dataList, columnList} = props;

        let mapDataIndexByFrontendId = {};
        for (let i = 0; i < dataList.length; i++) {
            mapDataIndexByFrontendId[dataList[i]["f_id"]] = i;
        }
        setColumns(columnList);
        columnsRef.current = columnList;
        setData(dataList);
        setDataMapIndexByFId(mapDataIndexByFrontendId);

        lastUpdateDateRef.current = dayjs().format('YYYY-MM-DDTHH:mm:ssZ');
        // setLastUpdateDate();

        console.log("TableGrid.props=", props, "; map data by index = ", mapDataIndexByFrontendId);
    }, [props?.id]);

    // useEffect(() => {
    //     // Update the current value of the ref to the latest state
    //     lastUpdateDateRef.current = lastUpdateDate;
    // }, [lastUpdateDate]);


    async function makeCellUpdates(response) {
        const cellsNeedToUpdateList = response;
        // console.log("[fetchCellUpdates] dataMapIndexByFId = ", dataMapIndexByFId);
        //2024-04-01T13:26:02+03:00Z
        // todo:
        // create map with fid
        // update cell by fid
        // by map we undestand index
        // how understand column?

        // console.log("mapDataIndexByFrontendId = ", dataMapIndexByFId);
        let gridUpdatedCells = [];
        // let copyData = Object.assign([], data)

        let columnKeyList = {};
        if (data.length > 0) {
            columnKeyList = Object.keys(data[0]);
        }
        // console.log("columnKeyList = ", columnKeyList);
        for (let i = 0; i < cellsNeedToUpdateList.length; i++) {
            const cellNeedUpdate = cellsNeedToUpdateList[i];
            const frontendIdFromUpdate = cellNeedUpdate["frontend_row_id"];
            const rowIndex = dataMapIndexByFId[frontendIdFromUpdate];
            // console.log("selected data = ", data[rowIndex], "; need to update = ", cellNeedUpdate, "; fid from update = ",frontendIdFromUpdate,"; rowIndex = ",rowIndex, "dataMapIndexByFId = ", dataMapIndexByFId);
            if (data[rowIndex]["f_id"] != cellNeedUpdate["frontend_row_id"]) {
                console.error("frontend id is not match. fId from the data = ", data[rowIndex]["f_id"], "; fId from the update data = ", cellNeedUpdate["frontend_row_id"]);
                continue;
            }
            const columnId = cellNeedUpdate["column_id"];
            const cellData = JSON.parse(cellNeedUpdate["data"]);
            console.log("[makeCellUpdates] updated cell data= ", cellData);
            const columnIndex = columnKeyList.indexOf(columnId);
            data[rowIndex][columnId] = cellData;
            // console.log("gridUpdatedCells")
            gridUpdatedCells.push({cell: [columnIndex, rowIndex]});
        }

        await setData(data);
        // console.log("[makeCellUpdates] grid update cells = ", gridUpdatedCells);
        ref.current?.updateCells(gridUpdatedCells);
    }

    const fetchCellUpdates = async () => {
        // setLastUpdateDate(dayjs().format('YYYY-MM-DDTHH:mm:ssZ'));
        // addRequest(tableId);

        const response = await getCellUpdatesForSelectedTableRequest(tableId, lastUpdateDateRef.current);
        lastUpdateDateRef.current = dayjs().format('YYYY-MM-DDTHH:mm:ssZ');
        // const response = await getCellUpdatesForSelectedTableRequest(tableId, '2024-04-01T13:26:02+03:00Z');
        await makeCellUpdates(response["data"]);
        // removeRequest(tableId);
    };

    useEffect(() => {
        // Set up an interval to call the function every 2 seconds
        const intervalId = setInterval(fetchCellUpdates, TABLE_UPDATE_FREQUENCY);
        // Clean up: clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [dataMapIndexByFId]); // The effect depends on `tableId`; it will re-run if `tableId` changes


    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    const sendSizeToBackend = async (column, newSize) => {
        console.log("Sending new size to backend: ", column, newSize);
        // Your code to send the new size to the backend goes here
        addRequest(tableId);
        await putColumnWidthSizeRequest(column.id, newSize);
        removeRequest(tableId);
    };

    const debouncedOnResizeEnd = debounce((column, newSize) => {
        sendSizeToBackend(column, newSize);
    }, 300);

    const onColumnResize = useCallback((column, newSize) => {
        // console.log("onColumnResize callback: column = ", column, "; newSize = ", newSize);
        setColumns((prevColsMap) => {
            // console.log("onColumnResize setColumns: prevColsMap = ", prevColsMap, "columns = ", columns);
            const index = columnsRef.current.findIndex((ci) => ci.id === column.id);
            const newArray = [...prevColsMap];
            newArray.splice(index, 1, {
                ...prevColsMap[index],
                width: newSize
            });
            // console.log("onColumnResize setColumns: index = ", index, "; newArray = ", newArray);
            columnsRef.current = newArray;
            return newArray;
        });
        debouncedOnResizeEnd(column, newSize);
    }, []);

    const getContent = useCallback(
        (cell) => {
            const [col, row] = cell;
            const dataRow = data[row];
            const columnId = columns[col].id;
            const cellObject = dataRow[columns[col].id];

            const isCellNotEmpty = cellObject != undefined && cellObject.hasOwnProperty("d");
            let d = "";
            if (isCellNotEmpty) {
                d = cellObject["d"];
            }

            // console.log(" columns[col] = ", columns[col]);
            // const {dataType} = columns[col];
            let cellDataType = columns[col]["f_type"];

            // console.log("getContent = ", cell, "; columns[col] = ", columns[col], "cellObject = ", cellObject);
            // console.log();

            if(cellObject != undefined) {

                if (columns[col]["f_type"] == "MARKDOWN") {
                    cellDataType = "Markdown";
                }

                if (cellObject != undefined && cellObject["m"] != null && cellObject["m"]["ldg"] == true) {
                    cellDataType = "Loading";
                }
            }

            // console.log("cellDataType = ", cellDataType);

            if (cellDataType === "Number") {
                return {
                    allowOverlay: true,
                    kind: GridCellKind.Number,
                    data: d,
                    displayData: d.toString()
                };
            } else if (cellDataType === "Image") {
                return {
                    kind: GridCellKind.Image,
                    data: [d],
                    allowOverlay: true,
                    allowAdd: true
                };
            } else if (cellDataType === "Bubble") {
                return {
                    kind: GridCellKind.Bubble,
                    data: ["sss", "ss"],
                    allowOverlay: true
                };
            } else if (cellDataType === "DROPDOWN") {
                const columnSettings = columns[col]["settings"];
                let dropdownValues = [];
                if (columnSettings.hasOwnProperty("values")) {
                    dropdownValues = columnSettings["values"];
                }
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: true,
                    copyData: 4,
                    data: {
                        kind: "dropdown-cell",
                        allowedValues: dropdownValues,
                        value: d
                    }
                };
            } else if (cellDataType === "DatePicker") {
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: true,
                    copyData: "4",
                    data: {
                        kind: "date-picker-cell",
                        date: new Date(),
                        displayDate: new Date().toISOString(),
                        format: "date"
                    }
                };
            } else if (cellDataType === "Loading") {
                return {
                    kind: GridCellKind.Custom,
                    readonly: true,
                    data: {
                        kind: "spinner-cell"
                    }
                };
            } else if(cellDataType === "Markdown") {
                return {
                    kind: GridCellKind.Markdown,
                    allowOverlay: true,
                    readonly: columns[col]["is_readonly"],
                    displayData: d,
                    data: d
                };
            } else if(cellDataType === "FILE") {
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: true,
                    readonly: false,
                    displayData: d,
                    data: {
                        kind: "file-cell",
                        value: d,
                        rowId: dataRow["f_id"],
                        columnId: columnId,
                        tableId: tableId
                    }
                };


                // return {
                //     kind: GridCellKind.Text,
                //         allowOverlay: true,
                //         readonly: columns[col]["is_readonly"],
                //         displayData: d,
                //         data: d
                // };

            } else if (cellDataType == "DOWNLOAD_LINK") {
                return {
                    kind: GridCellKind.Uri,
                    allowOverlay: true,
                    readonly: columns[col]["is_readonly"],
                    displayData: d,
                    data: d
                };
            } else {
                return {
                    kind: GridCellKind.Text,
                    allowOverlay: true,
                    readonly: columns[col]["is_readonly"],
                    displayData: d,
                    data: d
                };
            }
        },
        [data, columns]
    );


    const onCellEdited = useCallback(
        (cell, newValue) => {
            const [col, row] = cell;
            console.log("onCellEdited = ", cell, newValue);
            setData((prevData) => {
                console.log("onCellEdited (setData) = ", cell, prevData);
                const newData = [...prevData];
                const selectedRow = newData[row];
                if (selectedRow != undefined) {
                    const rowId = newData[row]["f_id"];
                    const columnId = columns[col].id;
                    if (newValue.kind !== GridCellKind.Custom) {
                        newData[row][columnId]["d"] = newValue.data;
                    } else {
                        newData[row][columnId] = newValue.data.value;
                    }
                    props.onCellValueChanged(columnId, rowId, newData[row][columnId]);
                    // Return the new state
                    return newData;
                } else {
                    return prevData;
                }
            });
            // const rowId = data[row]["f_id"];
            // const columnId = columns[col].id;
            // if (newValue.kind !== GridCellKind.Custom) {
            //     data[row][columnId]["d"] = newValue.data;
            //     // props.onCellValueChanged(columnId, rowId, newValue.data);
            // } else {
            //     data[row][columnId] = newValue.data.value;
            // }
            // props.onCellValueChanged(columnId, rowId, data[row][columnId]);
            // console.log("onCellEdited: rowId=", rowId, "; columnId=", columnId, "; value=", newValue, "; cell=", cell, data[row]);
            // setData(data);
        },
        [data, columns]
    );

    const onDragOverCell = (cell) => {
        console.log(cell);
    };

    const onRowAppended = useCallback(() => {
        const rowId = getRandomId(15);
        let newRowObj = {
            "f_id": rowId
        };
        for (let i = 0; i < columns.length; i++) {
            newRowObj[columns[i]["id"]] = {"d": "", "m": {"ldg": false, "dsbl": false}};
        }

        setData([...data, newRowObj]);
        props.onRowNew(tableId, rowId)
    }, [data, columns]);

    const onHeaderClicked = useCallback(() => {
        console.log("Header clicked");
    }, []);

    const onOutsideClick = () => {
        if (isSubMenuOpen) {
            setShowMenu(undefined);
            setIsSubMenuOpen((cv) => !cv);
        }
        setIsSubMenuOpen((cv) => !cv);
    };

    // const {renderLayer, layerProps} = useLayer({
    //     isOpen: showMenu !== undefined,
    //     triggerOffset: 2,
    //     onOutsideClick,
    //     trigger: {
    //         getBounds: () => ({
    //             bottom: (showMenu?.bounds.y ?? 0) + (showMenu?.bounds.height ?? 0),
    //             height: showMenu?.bounds.height ?? 0,
    //             left: showMenu?.bounds.x ?? 0,
    //             right: (showMenu?.bounds.x ?? 0) + (showMenu?.bounds.width ?? 0),
    //             top: showMenu?.bounds.y ?? 0,
    //             width: showMenu?.bounds.width ?? 0
    //         })
    //     },
    //     placement: "bottom-end",
    //     auto: true
    // });

    const onHeaderMenuClick = useCallback((col, bounds) => {
        setIsSubMenuOpen((cv) => !cv);
        setShowMenu({col, bounds});

        setSelectedColumn(col);
        setPopoverVisible(true);
        setPopoverBounds(bounds);
        console.log("onHeaderMenuClick: ", col, bounds);

    }, []);


    const onAddColPopup = () => {

    };

    const onColumnCreation = () => {
        props.onUpdate();
    };

    const onAddCol = useCallback(() => {
        const newData = data.map((row) => {
            return {...row, new: ""};
        });
        setData(newData);
        // setIndexes([...Object.keys(data[0]), "new"]);
        setColumns([
            ...columns,
            {
                title: "New",
                id: "new",
                hasMenu: true
            }
        ]);
    }, [data, columns]);

    const onColMoved = useCallback((startIndex, endIndex) => {
        setColumns((old) => {
            const newCols = [...old];
            const [toMove] = newCols.splice(startIndex, 1);
            newCols.splice(endIndex, 0, toMove);
            // console.log("handleColumnOrderUpdate: ", startIndex, endIndex, old, );
            const columnId = old[startIndex]["id"]
            props.onColumnMoved(columnId, endIndex);
            return newCols;
        });
    }, []);




    const handleColumnDelete = () => {
        const columnData = columns[selectedColumn];
        console.log("handleColumnDelete = ", columnData, selectedColumn);
        confirm({
            title: `Do you want to delete ${columnData["title"]} column?`,
            icon: <ExclamationCircleFilled/>,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                props.onColumnDelete(columnData["id"]);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleColumnEdit = () => {
        const columnData = columns[selectedColumn];
        console.log("handleColumnDelete = ", columnData, selectedColumn);
        props.onColumnEdit(columnData);
        setPopoverVisible(false);
    }


    const handleChangeGridSelection = (newSelection) => {
        setSelection(newSelection);
        const selectedRows = newSelection.rows.toArray();

        setSelectedRowList(selectedRows);
        // props.onRowSelect(selectedRows)
        // if(selectedRows.length > 0) {
        // }

    }

    const regenerateRow = async (frontendRowId, tableId) => {
        addRequest(tableId);
        const response = await postRowRegenerationRequest(tableId, frontendRowId);
        removeRequest(tableId);
        await makeCellUpdates(response["cell_updates"]);
    }

    const handleRegenerateRowList = () => {
        console.log("[handleRegenerateRowList] selectRowList = ", selectedRowList);

        for (let i = 0; i < selectedRowList.length; i++) {
            const dataItem = data[selectedRowList[i]];
            const rowFrontendId = dataItem["f_id"];

            regenerateRow(rowFrontendId, tableId);

        }
    }


    const regenerateCell = async (frontendRowId, columnId, tableId) => {
        addRequest(tableId);
        const response = await postCellRegenerationRequest(tableId, frontendRowId, columnId);
        removeRequest(tableId);
        await makeCellUpdates(response["cell_updates"]);
    }


    const handleCellRecalculate = () => {
        const cellIndexes = selectedCell.cell;

        const columnIndex = cellIndexes[0];
        const rowIndex = cellIndexes[1];


        const dataItem = data[rowIndex];

        const rowFrontendId = dataItem["f_id"];
        const columnId = columns[columnIndex].id;

        console.log("columnIndex = ", columnIndex , "; rowIndex = ", rowIndex, "; column id = ",columnId);

        regenerateCell(rowFrontendId, columnId, tableId);

        setSelectedCell(null);
        setIsContextMenuVisible(false);

        // const dataItem = data[selectedRowList[i]]
    }

    const handleDeleteSelectedRows = () => {
        const rowsToDelete = selectedRowList.map(rowIndex => data[rowIndex].f_id);
        console.log("selectedRowList = ", selectedRowList, "; rowsToDelete = ", rowsToDelete);
        handleRowDelete(rowsToDelete);
        setSelection({ ...selection, rows: CompactSelection.empty()});
        setSelectedRowList([]);
    };

    const handleDeleteRequest = async (rowIds) => {
        addRequest(tableId);
        await deleteRowListRequest(tableId, rowIds);
        removeRequest(tableId);
    }

    const handleRowDelete = (rowIds) => {
        handleDeleteRequest(rowIds);
        const newData = data.filter(row => !rowIds.includes(row.f_id));
        console.log("newData =", newData);
        setData(newData);
        const newMapIndexByFId = {};
        newData.forEach((row, index) => {
            newMapIndexByFId[row.f_id] = index;
        });
        console.log("newMapIndexByFId =", newMapIndexByFId);
        setDataMapIndexByFId(newMapIndexByFId);
    };

    // const onDelete = (select) => {
    //     const rowsToDelete = select.rows.toArray();
    //     console.log("rowsToDelete = ",rowsToDelete);
    //     // const rowIdsToDelete = rowsToDelete.map(rowIndex => data[rowIndex].f_id);
    //
    //     const validRowsToDelete = rowsToDelete.filter(rowIndex => data[rowIndex] != undefined);
    //     const rowIdsToDelete = validRowsToDelete.map(rowIndex => data[rowIndex].f_id);
    //
    //     console.log("validRowsToDelete = ", validRowsToDelete);
    //
    //     if (validRowsToDelete.length == 0) {
    //         setSelection({ ...selection, rows: CompactSelection.empty()});
    //         setSelectedRowList([]);
    //         return false;
    //     } else {
    //         handleRowDelete(rowIdsToDelete);
    //         return true;
    //     }
    //
    // };

    let backgroundLoadingRender = <span style={{color: "#52C41A"}}><FontAwesomeIcon icon={faCheck}/></span>;

    const { isLoading } = useLoader();

    // todo: fix while loading in external module
    if (isLoading(tableId)) {
        backgroundLoadingRender = <span><Spin indicator={<LoadingOutlined style={{fontSize: 16}} spin/>}/></span>;
    }

    let additionalControls = null;
    if (selectedRowList.length > 0) {
        additionalControls =
            <Col span={15}>
                <Button onClick={handleRegenerateRowList} size={"small"} type="primary" ghost>Regenerate row answers</Button> &nbsp;
                <Popconfirm
                    title="Delete rows"
                    description="Are you sure to delete selected rows?"
                    onConfirm={handleDeleteSelectedRows}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                    <Button size={"small"} danger>Delete selected rows</Button>
                </Popconfirm>
            </Col>
    }

    let spaceControls = <Row>
        <Col style={{paddingRight: "10px", paddingTop: "2px", width: "20px"}}>{backgroundLoadingRender}</Col>
        {additionalControls}
    </Row>;

    // const {customRenderers} = useExtraCells();
    const allCustomRenders = [FileCustomCell, LinksCell, SpinnerCell, DropdownCell];
    // let allCustomRenders = customRenderers.concat(ourCustomRenders)

    return (
        <div className="App">
            <div style={{background: "#fff", padding: "7px 10px", height: "38px"}}>
                {spaceControls}
            </div>

            {/* <button onClick={() => setShowSearch(true)}>
                  Show Search
            </button> */}
            {/*{dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]') }*/}
            <DataEditor
                ref={ref}
                gridSelection={selection}
                onGridSelectionChange={handleChangeGridSelection}
                customRenderers={allCustomRenders}
                getCellContent={getContent}
                columns={columns}
                onCellEdited={onCellEdited}
                onHeaderMenuClick={onHeaderMenuClick}
                onHeaderClicked={onHeaderClicked}
                // onCellContextMenu={(_, e) => e.preventDefault()}
                onCellContextMenu={(cell, event) => {
                    event.preventDefault()
                    const eventCellData = {cell: cell, event: event};
                    setSelectedCell(eventCellData);
                    setIsContextMenuVisible(true);
                    console.log("onCellContextMenu = ", cell, event);
                }}
                rows={data.length}
                rowMarkers={"both"}
                showSearch={showSearch}
                getCellsForSelection={true}
                onSearchClose={onSearchClose}
                // onDelete={onDelete}
                onRowAppended={onRowAppended}
                onDragOverCell={onDragOverCell}
                onRowMoved={(s, e) => console.log(`onRowMoved: Moved row ${s} to ${e}`)}
                // height={"500px"}
                width={"100%"}
                onColumnMoved={onColMoved}
                trailingRowOptions={{
                    // How to get the trailing row to look right
                    sticky: true,
                    tint: false,
                    hint: "New row...",
                    themeOverride: true
                }}
                smoothScrollX={true}
                smoothScrollY={true}
                verticalBorder={(c) => c > 0}
                // freezeColumns={1}
                onDragStart={(e) => {
                    e.setData("text/plain", "Drag data here!");
                }}
                rightElement={
                    <NewColumnPopup tableId={tableId} typeList={typeList} onUpdate={onColumnCreation}/>
                }

                rangeSelect={"multi-rect"}

                // original content
                // rightElement={
                //     <div className="addCol">
                //         <button onClick={() => onAddCol()}>+</button>
                //     </div>
                // }
                rightElementProps={{
                    fill: false,
                    sticky: true
                }}
                onColumnResize={onColumnResize}
            />
            <div id="portal"/>


            {/* column content menu  */}
            {selectedColumn != null ? (
                <Dropdown
                    overlay={<Menu>
                        <Menu.Item key="1" onClick={handleColumnEdit}>Edit field</Menu.Item>
                        <Divider style={{margin: 0}}/>
                        <Menu.Item key="2">Insert left</Menu.Item>
                        <Menu.Item key="3">Insert right</Menu.Item>
                        <Divider style={{margin: 0}}/>
                        <Menu.Item danger={true} key="delete" onClick={handleColumnDelete}>Delete field</Menu.Item>
                    </Menu>}
                    visible={popoverVisible}
                    trigger={['click']}
                    onVisibleChange={setPopoverVisible}
                >
                    <div style={{width: '0px', height: '0px'}} style={{
                        position: 'absolute',
                        left: `${popoverBounds.x + popoverBounds.width - 40}px`,
                        top: `${popoverBounds.y + popoverBounds.height}px`,
                    }}/>
                </Dropdown>) : null}


            {/*right button context cell menu */}
            {selectedCell != null ? (
                <Dropdown
                    overlay={<Menu>
                        <Menu.Item key="1" onClick={handleCellRecalculate}>Recalculate field</Menu.Item>
                    </Menu>}
                    visible={isContextMenuVisible}
                    trigger={['click']}
                    onVisibleChange={setIsContextMenuVisible}
                >
                    <div style={{width: '0px', height: '0px'}} style={{
                        position: 'absolute',
                        left: `${selectedCell.event.bounds.x + selectedCell.event.localEventX }px`,
                        top: `${selectedCell.event.bounds.y + selectedCell.event.localEventY }px`,
                    }}/>
                </Dropdown>) : null}

        </div>
    );
};

export default TableGrid;

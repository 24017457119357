import {Col, Divider, Form, Row, Spin, Input, Checkbox, Button, Card} from "antd";
import {useEffect, useState} from "react";
import "../unauth.css";
import usePublicApi from "../utils/publicRequests";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useNotification} from "../utils/NotificationContext";

function ConfirmForgotPasswordView(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {postConfirmResetPassword} = usePublicApi();
    const notification = useNotification();

    const [isLoading, setIsLoading] = useState(false);

    const handleRequest  = async (postData) => {
        setIsLoading(true);
        const {statusCode, data} = await postConfirmResetPassword(postData);
        const isOk = statusCode >= 200 && statusCode < 300;

        if (isOk) {
            console.log("ok");
            notification.success({
                message: 'Password has been changed',
                placement: 'bottomLeft',
            });
            setIsLoading(false);
            navigate("/signin");
        }

        setIsLoading(false);
    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);

        const sendBody = {
            "token": searchParams.get("token"),
            "new_password": values["password"]
        }
        handleRequest(sendBody);
    };

    return (
        <div style={{
            width: '100%',
            background: "#f5f5f5",
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Card title="Password recovery" style={{width: "400px"}}>

                <Form
                    form={form}
                    name="login"
                    onFinish={onFinish}
                    scrollToFirstError
                >

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password size={"large"} placeholder="Password"/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password size={"large"} placeholder="Confirm Password"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Reset password
                        </Button>
                    </Form.Item>
                </Form>

                <Row>
                    <Col span={24}><Link to={"/signup"}>Signup</Link> or <Link to={"/signin"}>Sign in</Link></Col>
                </Row>
            </Card>

        </div>
    );
}


export default ConfirmForgotPasswordView;

import React, {useEffect, useState} from 'react';
import {Form, Input, Select, Row, Col, Divider, Radio, Button, Card, Switch} from 'antd';
import {ENV_API_HOST} from "../config";
import {useApi} from "./requests";

import {Form as FormCustom} from '@rjsf/antd';
import {RJSFSchema} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import TriggerRichEditor from "./TriggerRichEditor/TriggerRichEditor";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const ColumnEditForm = ({form, initValue, onSubmit, handleFormChanges}) => {
    const {getDataTypeListRequest, getTableColumnListRequest} = useApi();
    const [isDataTypeLoading, setIsDataTypeLoading] = useState(false);
    const [dateTypeList, setDataTypeList] = useState([]);

    useEffect(() => {
        getDataTypes();
    }, []);

    const getDataTypes = async () => {
        setIsDataTypeLoading(true);
        const dataTypeListResponse = await getDataTypeListRequest();
        let mappedList = [];
        for (let i = 0; i < dataTypeListResponse.length; i++) {
            mappedList.push({
                value: dataTypeListResponse[i]["key"],
                label: dataTypeListResponse[i]["label"]
            })
        }
        setDataTypeList(mappedList);
        setIsDataTypeLoading(false);

    }

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={initValue}
            onFinish={onSubmit} // onFinish triggers when the form is submitted and validated successfully
            onValuesChange={handleFormChanges}
        >
            <Row>
                <Col span={24}>
                    <Form.Item name={"name"} label={"Field name (optional):"}>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={"type"} label={"Field type:"}>
                        <Select options={dateTypeList}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

// {
//   "column_type_id": 1,
//   "execution_rule": {
//     "init_type": "",
//     "init_condition": "",
//     "init_value": ""
//   },
//   "effect": {
//     ""
//   }
// }


//Rule types:
// - ON_ROW_CREATED
// - ON_ROW_CHANGED
// - ON_ROW_DELETED
//
// - ON_COLUMN_VALUE_CHANGED_FIRST
// - ON_COLUMN_VALUE_CHANGED
// - ON_COLUMN_VALUE_CLEAR
// - ON_COLUMN_VALUE_STATUS
//
// - ON_TRIGGER_SEQUENCE
// - ON_USER_CLICK


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 20,
        },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 0,
        },
    },
};

function triggerFormGenerator(pluginSchemaFieldList, triggerData, handleRichEditorChanges, tableId) {
    let formRender = [];
    for (let i = 0; i < pluginSchemaFieldList.length; i++) {
        const pluginSchemaRow = pluginSchemaFieldList[i];

        const defaultValue = pluginSchemaRow["default_value_json"];

        if (pluginSchemaRow["field_frontend_type"] == "INPUT" && !pluginSchemaRow["is_list"]) {
            formRender.push(<Form.Item key={`${pluginSchemaRow["field_name"]}:`}
                                       label={`${pluginSchemaRow["field_name"]}:`}
                                       name={`plugin_schema_field:${pluginSchemaRow["id"]}`}>
                <Input/>
            </Form.Item>)
        }

        if (pluginSchemaRow["field_frontend_type"] == "INPUT" && pluginSchemaRow["is_list"]) {

            const formListItem = <Form.List
                name={`plugin_schema_field:${pluginSchemaRow["id"]}`}
                // rules={[
                //     {
                //         validator: async (_, names) => {
                //             if (!names || names.length < 2) {
                //                 return Promise.reject(new Error('At least 2 passengers'));
                //             }
                //         },
                //     },
                // ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            whitespace: true,
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input
                                        style={{
                                            width: '60%',
                                        }}
                                    />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add(null)}
                                style={{
                                    width: '60%',
                                }}
                                icon={<PlusOutlined />}
                            >
                                Add field
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>;

            formRender.push(formListItem);
        }


        if (pluginSchemaRow["field_frontend_type"] == "SELECT") {
            formRender.push(<Form.Item key={`${pluginSchemaRow["field_name"]}:`}
                                       label={`${pluginSchemaRow["field_name"]}:`}
                                       name={`plugin_schema_field:${pluginSchemaRow["id"]}`}>
                <Select options={defaultValue}/>
            </Form.Item>)
        }

        if (pluginSchemaRow["field_frontend_type"] == "RICHTEXT") {
            let fieldKey = `plugin_schema_field:${pluginSchemaRow["id"]}`;

            let richTextContent = "";
            if (triggerData[fieldKey] != undefined) {
                richTextContent = triggerData[fieldKey];
            }
            // console.log("rich field; field key = ", fieldKey, "; triggerdata = ", triggerData);


            formRender.push(<div key={`${pluginSchemaRow["field_name"]}:`}>
                    <Row style={{marginBottom: "5px"}}>
                        <Col span={24}>
                            {pluginSchemaRow["field_name"]}:
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TriggerRichEditor

                                content={richTextContent}
                                name={fieldKey}
                                onUpdate={handleRichEditorChanges}
                                tableId={tableId}
                            />
                        </Col>
                    </Row>
                </div>
            )
        }

        if(pluginSchemaRow["field_frontend_type"] == "SWITCH") {
            formRender.push(<Form.Item key={`${pluginSchemaRow["field_name"]}:`}
                                       label={`${pluginSchemaRow["field_name"]}:`}
                                       name={`plugin_schema_field:${pluginSchemaRow["id"]}`}
                                       valuePropName="checked"
            >
                <Switch  />
            </Form.Item>)
        }
    }
    return formRender;
}

const NewTriggerForm = (props) => {
    const {getColumnTypeDetailsRequest, getTableColumnListRequest} = useApi();

    const {tableId, triggerData, columnId} = props;

    const [defaultEventType, setDefaultEventType] = useState("ON_MANUAL_RUN");

    const columnEventTypeList = ["ON_COLUMN_VALUE_CHANGED", "ON_COLUMN_VALUE_CHANGED_FIRST", "ON_COLUMN_VALUE_CLEAR", "ON_COLUMN_VALUE_STATUS"]
    const rowEventTypeList = ["ON_ROW_CREATED", "ON_ROW_CHANGED", "ON_ROW_DELETED"];

    const [form] = Form.useForm();
    const [eventType, setEventType] = useState(defaultEventType);
    const [pluginSchemaFieldList, setPluginSchemaFieldList] = useState([]);

    const [isColumnListLoading, setIsColumnListLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);


    const [richFieldSnapshot, setRichFieldSnapshot] = useState({});
    const [formSnapshot, setFormSnapshot] = useState({});

    const [existingColumnList, setExistingColumnList] = useState([]);
    const [isExistingColumnListLoading, setIsExistingColumnListLoading] = useState(false);

    const updateTableColumnList = async (tableId) => {
        setIsExistingColumnListLoading(true);
        const response = await getTableColumnListRequest(tableId);
        // console.log("[updateTableColumnList] response=", );
        const tableColumnList = response["column_list"];
        let columnSelectList = [];
        for (let i = 0; i < tableColumnList.length; i++) {
            columnSelectList.push({
                "value": tableColumnList[i]["id"].toString(),
                "label": tableColumnList[i]["name"]
            })
        }
        setExistingColumnList(columnSelectList);
        setIsExistingColumnListLoading(false);
    }

    useEffect(() => {
        const {columnTypeId, fieldSchema} = props;
        console.log("TriggerFormEffect: columnTypeId = ", columnTypeId, "; columnId = ", columnId, "triggerData = ", triggerData);
        updateTableColumnList(tableId);
        if (columnTypeId != null) {
            console.log("new column type id = ", columnTypeId);
            setPluginSchemaFieldList(fieldSchema);
            // getMetaFieldsForSelectedColumn(columnTypeId);
        }

        // console.log("[useEffect] setFormSnapshot = ", triggerData);
        setFormSnapshot(triggerData);

        const triggerDataKeyList = Object.keys(triggerData)


        form.setFieldValue("init_event_type", triggerData["init_event_type"]);

        // @todo: hotfix, i need to depply understand how forms here works and fix it, it easy but need to review
        if (triggerData["init_rule_column"] !== undefined || triggerData["init_rule_column"] != null) {
            form.setFieldValue("init_rule_column", triggerData["init_rule_column"]);
        }

        let richDataObj = {}
        for (let i = 0; i < triggerDataKeyList.length; i++) {
            if (triggerDataKeyList[i].includes("plugin_schema_field")) {
                richDataObj[triggerDataKeyList[i]] = triggerData[triggerDataKeyList[i]]
            }
        }

        setRichFieldSnapshot(richDataObj);
        form.setFieldsValue(triggerData);

    }, [props?.columnTypeId, columnId])

    // useEffect(() => {
    // }, [props?.triggerData])


    const getMetaFieldsForSelectedColumn = async (columnId) => {
        setIsMetaDataLoading(true);
        const metaData = await getColumnTypeDetailsRequest(columnId);
        console.log("getMetaFieldsForSelectedColumn:metadata = ", metaData);
        setPluginSchemaFieldList(metaData["column_type"]["plugin_fields"])
        setIsMetaDataLoading(false);
    }

    const completeForm = (allValues) => {
        const keyList = Object.keys(richFieldSnapshot);
        for (let i = 0; i < keyList.length; i++) {
            if (!allValues.hasOwnProperty(keyList[i])) {
                allValues[keyList[i]] = richFieldSnapshot[keyList[i]];
            }
        }

        console.log("[completeForm] allValues = ", allValues, richFieldSnapshot);
        return allValues;
    }

    // const updateColumnSelectValues = () => {
    //     setIsColumnListLoading(true);
    //
    //     setIsColumnListLoading(false);
    // }

    // @todo: bug here with old values in allValues = https://stackoverflow.com/questions/48033720/ant-design-form-validate-returns-old-values-while-using-onchange
    const handleChange = (changedValues, allValues) => {

        if (changedValues["init_event_type"] != null) {
            setEventType(changedValues["init_event_type"]);
            setDefaultEventType(changedValues["init_event_type"]);
        }

        console.log("[handleChange] BEFORE updated allValues = ", allValues);

        //
        // const changeKeyList = Object.keys(changedValues);
        // for (let i = 0; i < changeKeyList.length; i++) {
        //     allValues[changeKeyList[i]] = changedValues[changeKeyList[i]];
        // }

        // console.log("[handleChange] updated allValues = ", allValues, changeKeyList);
        const formData = completeForm(allValues);
        console.log("[handleChange] formEvent = ", changedValues, allValues, formData);
        setFormSnapshot(formData);
        props.onFormUpdate(formData);
    }

    const handleRichEditorChanges = (fieldName, fieldData) => {
        console.log("[handleRichEditorChanges] formEvent = ", fieldName, fieldData, richFieldSnapshot, formSnapshot);
        richFieldSnapshot[fieldName] = fieldData;
        const newRichFieldSnapshot = Object.assign({}, richFieldSnapshot);
        setRichFieldSnapshot(newRichFieldSnapshot);
        formSnapshot[fieldName] = fieldData;
        setFormSnapshot(formSnapshot);
        props.onFormUpdate(formSnapshot);
    }


    // const handleRichEditorChanges = (name, updatedData) => {
    //     console.log("handleRichEditorChanges; name=", name, "; updatedData = ", updatedData);
    //     props.onRichFieldUpdate(name, updatedData);
    // }

    if (pluginSchemaFieldList.length == 0) {
        return <span><Card>You can't use triggers for this field because it is not set up.</Card></span>;
    }


    console.log("form re render: form = ", form.getFieldsValue(), "; triggerData = ", triggerData, formSnapshot, "trigger event = ", eventType, columnEventTypeList);
    let formRender = triggerFormGenerator(pluginSchemaFieldList, formSnapshot, handleRichEditorChanges, tableId);

    return (
        <Form layout="vertical" form={form} onValuesChange={handleChange} initialValues={formSnapshot}>
            <Row>
                <Col>
                    <h4>New Trigger</h4>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name={"init_event_type"} initialValue={defaultEventType}
                               label={"If the following event occurs:"} style={{marginBottom: "12px"}}>
                        <Select options={[
                            // {
                            //     label: 'Row changes',
                            //     options: [
                            //         {value: 'ON_ROW_CREATED', label: 'New row created'},
                            //         {value: 'ON_ROW_CHANGED', label: 'Current row has been changed'},
                            //         {value: 'ON_ROW_DELETED', label: 'Current row has been deleted'},
                            //     ],
                            // },
                            {
                                label: 'Column changes',
                                options: [
                                    {
                                        value: 'ON_COLUMN_VALUE_CHANGED',
                                        label: 'Value in selected column has been changed'
                                    },
                                    // {
                                    //     value: 'ON_COLUMN_VALUE_CHANGED_FIRST',
                                    //     label: 'Value in selected column has been changed first time only'
                                    // },
                                    // {
                                    //     value: 'ON_COLUMN_VALUE_CLEAR',
                                    //     label: 'Value in selected column has been deleted (emply cell)'
                                    // },
                                    // {
                                    //     value: 'ON_COLUMN_VALUE_STATUS',
                                    //     label: 'Status in selected column has been changed'
                                    // },
                                ],
                            },
                            {
                                label: 'Other events',
                                options: [
                                    {value: 'ON_MANUAL_RUN', label: 'Manual run in column cell'},
                                ],
                            },
                        ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {columnEventTypeList.indexOf(formSnapshot["init_event_type"]) != -1 ? <div>
                <Row>
                    <Col span={24}>
                        <Form.Item name={"init_rule_column"} label={"In the column:"} style={{marginBottom: "12px"}}>
                            <Select placeholder={"Select in target column:"} loading={isExistingColumnListLoading} options={existingColumnList}/>
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row>*/}
                {/*    <Col span={24}>*/}
                {/*        <Form.Item name={"init_rule_condition"} label={"We reach next condition:"}*/}
                {/*                   style={{marginBottom: "12px"}}>*/}
                {/*            <Select placeholder={"Select condition"} options={[*/}
                {/*                {label: "Equal", value: "1"},*/}
                {/*                {label: "Not equal", value: "2"},*/}
                {/*                {label: "Is empty", value: "3"},*/}
                {/*                {label: "Is empty", value: "4"},*/}
                {/*            ]}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*    <Col span={24}>*/}
                {/*        <Form.Item name={"init_rule_value"}>*/}
                {/*            <Input placeholder={"Type here target value which is part of condition formula"}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </div> : null}


            <Row>
                <Col span={24}>
                    <Divider orientation="left" plain>
                        Then do the following:
                    </Divider>
                </Col>
            </Row>


            <Row>
                <Col span={24}>
                    Enter what you would like to do in this column (cell):
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div>{formRender}</div>
                </Col>
            </Row>
        </Form>
    )
}


const ColumnSettingsForm = (props) => {

    const {tableId, triggerData} = props;
    const {getTableColumnListRequest} = useApi();

    const [defaultEventType, setDefaultEventType] = useState("ON_MANUAL_RUN");

    const [form] = Form.useForm();
    const [eventType, setEventType] = useState(defaultEventType);
    const [pluginSchemaFieldList, setPluginSchemaFieldList] = useState([]);

    const [richFieldSnapshot, setRichFieldSnapshot] = useState({});
    const [formSnapshot, setFormSnapshot] = useState({});

    const [existingColumnList, setExistingColumnList] = useState([]);
    const [isExistingColumnListLoading, setIsExistingColumnListLoading] = useState(false);

    const updateTableColumnList = async (tableId) => {
        setIsExistingColumnListLoading(true);
        const response = await getTableColumnListRequest(tableId);
        // console.log("[updateTableColumnList] response=", );
        const tableColumnList = response["column_list"];
        let columnSelectList = [];
        for (let i = 0; i < tableColumnList.length; i++) {
            columnSelectList.push({
                "value": tableColumnList[i]["id"].toString(),
                "label": tableColumnList[i]["name"]
            })
        }
        setExistingColumnList(columnSelectList);
        setIsExistingColumnListLoading(false);
    }

    useEffect(() => {
        const {columnTypeId, fieldSchema} = props;
        updateTableColumnList(tableId);
        if (columnTypeId != null) {
            console.log("new column type id = ", columnTypeId);
            setPluginSchemaFieldList(fieldSchema);
            // getMetaFieldsForSelectedColumn(columnTypeId);
        }

        console.log("[useEffect] setFormSnapshot = ", triggerData);
        setFormSnapshot(triggerData);

        const triggerDataKeyList = Object.keys(triggerData)

        let richDataObj = {}
        for (let i = 0; i < triggerDataKeyList.length; i++) {
            if (triggerDataKeyList[i].includes("plugin_schema_field")) {
                richDataObj[triggerDataKeyList[i]] = triggerData[triggerDataKeyList[i]]
            }
        }

        console.log("[ColumnSettingsForm] richDataObj = ", richDataObj, "; triggerData = ", triggerData);
        setRichFieldSnapshot(richDataObj);
        form.setFieldsValue(triggerData);

    }, [props?.columnTypeId])

    // useEffect(() => {
    // }, [props?.triggerData])

    const completeForm = (allValues) => {
        const keyList = Object.keys(richFieldSnapshot);
        for (let i = 0; i < keyList.length; i++) {
            if (!allValues.hasOwnProperty(keyList[i])) {
                allValues[keyList[i]] = richFieldSnapshot[keyList[i]];
            }
        }

        console.log("[completeForm] allValues = ", allValues, richFieldSnapshot);
        return allValues;
    }

    // const updateColumnSelectValues = () => {
    //     setIsColumnListLoading(true);
    //
    //     setIsColumnListLoading(false);
    // }

    // @todo: bug here with old values in allValues = https://stackoverflow.com/questions/48033720/ant-design-form-validate-returns-old-values-while-using-onchange
    const handleChange = (changedValues, allValues) => {

        if (changedValues["init_event_type"] != null) {
            setEventType(changedValues["init_event_type"]);
            setDefaultEventType(changedValues["init_event_type"]);
        }

        console.log("[handleChange] BEFORE updated allValues = ", allValues);

        //
        // const changeKeyList = Object.keys(changedValues);
        // for (let i = 0; i < changeKeyList.length; i++) {
        //     allValues[changeKeyList[i]] = changedValues[changeKeyList[i]];
        // }

        // console.log("[handleChange] updated allValues = ", allValues, changeKeyList);
        const formData = completeForm(allValues);
        console.log("[handleChange] formEvent = ", changedValues, allValues, formData);
        setFormSnapshot(formData);
        props.onFormUpdate(formData);
    }

    const handleRichEditorChanges = (fieldName, fieldData) => {
        console.log("[handleRichEditorChanges] formEvent = ", fieldName, fieldData, richFieldSnapshot, formSnapshot);
        richFieldSnapshot[fieldName] = fieldData;
        const newRichFieldSnapshot = Object.assign({}, richFieldSnapshot);
        setRichFieldSnapshot(newRichFieldSnapshot);
        formSnapshot[fieldName] = fieldData;
        setFormSnapshot(formSnapshot);
        props.onFormUpdate(formSnapshot);
    }


    // const handleRichEditorChanges = (name, updatedData) => {
    //     console.log("handleRichEditorChanges; name=", name, "; updatedData = ", updatedData);
    //     props.onRichFieldUpdate(name, updatedData);
    // }

    if (pluginSchemaFieldList.length == 0) {
        return null;
    }

    let formRender = triggerFormGenerator(pluginSchemaFieldList, formSnapshot, handleRichEditorChanges, tableId);

    return (
        <Form layout="vertical" form={form} onValuesChange={handleChange} initialValues={formSnapshot}>
            <Row>
                <Col>
                    <h4>Column Settings</h4>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <div>{formRender}</div>
                </Col>
            </Row>
        </Form>
    )
}



export {
    ColumnSettingsForm,
    ColumnEditForm,
    NewTriggerForm,
    triggerFormGenerator
}

import {Button, Col, Form, Input, Modal, Row, Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "../config";
import {ColumnEditForm, NewTriggerForm} from "../utils/Forms";
import {useApi} from "../utils/requests";
import {triggerDataToFormSerialization} from "../utils/etc";
import ColumnPopupFieldsManager from "./ColumnPopupFieldsManager";

const EditColumnPopup = (props) => {
    const {putColumnDataRequest, getSelectedColumnTypeDataRequest} = useApi();

    const {isOpenEditModal, columnMetaData, tableId} = props;
    const [isLoading, setIsLoading] = useState(false);
    // const [initValues, setInitValues] = useState({});
    const [columnTypeId, setColumnTypeId] = useState(null);
    const [isTriggerListLoading, setIsTriggerListLoading] = useState(false);
    const [triggerList, setTriggerList] = useState([]);
    const [triggerDataSnapshot, setTriggerDataSnapshot] = useState(null);

    const [triggerFormData, setTriggerFormData] = useState({});
    const [columnSettings, setColumnSettings] = useState({});

    const [form] = Form.useForm();

    // todo: MOVED TO ColumnPopupFieldsManager.js
    // const gettingTriggerList = async (columnId, columnTypeId) => {
    //     setIsTriggerListLoading(true);
    //     const response = await getSelectedColumnTypeDataRequest(columnId, columnTypeId);
    //     console.log("getTriggerListForSelectedColumnRequest response: ", response);
    //     let formTriggerList = [];
    //     for (let i = 0; i < response["trigger_list"].length; i++) {
    //         const formTriggerData = triggerDataToFormSerialization(response["trigger_list"][i]);
    //         formTriggerList.push(formTriggerData);
    //     }
    //
    //     // todo: hotfix: because form doesnt render empty values
    //     if (formTriggerList.length == 0 && response["plugin_id"] != null) {
    //         formTriggerList = [{"init_event_type": "ON_MANUAL_RUN"}]
    //     }
    //
    //     setTriggerList(formTriggerList);
    //
    //     // todo: hotfix, need to fix in the future
    //     console.log("gettingTriggerList: formTriggerList= ", formTriggerList);
    //     if (formTriggerList.length > 0) {
    //         setTriggerDataSnapshot(formTriggerList[0]);
    //     }
    //     setIsTriggerListLoading(false);
    // }

    useEffect(() => {
        // какая то хуевая идея так делать
        // надо получать колонку с триггерами
        // возможно нет, тк триггеры в отдеьной форме
        console.log("useEffect for columnMetaData and isOpenEditModal");
        if (typeof columnMetaData["type"] == "number") {
            const columnTypeIdForSelect = columnMetaData["type"].toString();
            const initValues = {
                name: columnMetaData["title"],
                type: columnTypeIdForSelect
            };
            // console.log("useEffect: initValues = ",initValues, columnMetaData);
            form.setFieldsValue(initValues);
            setColumnTypeId(columnTypeIdForSelect)

            // TODO: CHECK THIS LOGIC
            // gettingTriggerList(columnMetaData["id"], columnMetaData["type"]);
        }
        // setInitValues(initValues);
    }, [props?.columnMetaData, props?.isOpenEditModal])

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("[handleOk] value = ", values);
                values["trigger"] = triggerFormData;
                values["column_settings"] = columnSettings;
                updateColumn(columnMetaData, values);
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }


    const updateColumn = async (columnMetaData, values) => {
        setIsLoading(true);
        await putColumnDataRequest(columnMetaData["id"], values);
        setIsLoading(false);
        props.onSuccess();
    }

    const handleColumnFormChanges = (changedValues, allValues) => {
        console.log("handleColumnFormChanges = ", changedValues, allValues);
        if (changedValues["type"] != null) {
            setColumnTypeId(changedValues["type"]);
            // setTriggerList([{"init_event_type": "ON_MANUAL_RUN"}]);
            // TODO: CHECK THIS LOGIC
            // gettingTriggerList(columnMetaData["id"], changedValues["type"]);
        }
    }

    const handleCancel = () => {
        props.onCancel();
    }


    const handleTriggerFormChanges = (formType, formData) => {
        console.log("we have updated our fields in tirgger: ", formData);
        // formData["id"] = triggerList[0]["id"];

        if (formType == "trigger") {
            setTriggerFormData(formData);
        }

        if (formType == "columnSettings") {
            setColumnSettings(formData);
        }
        // setTriggerDataSnapshot(formData);
    }


    // let triggerRender = null;
    // if (isTriggerListLoading) {
    //     triggerRender = <Spin/>;
    // } else if (!isTriggerListLoading && triggerList.length == 1) {
    //     triggerRender = <NewTriggerForm tableId={tableId} columnTypeId={columnTypeId} triggerData={triggerList[0]}
    //                                     onFormUpdate={handleTriggerFormChanges}/>;
    // } else if (!isTriggerListLoading && triggerList.length > 1) {
    //     triggerRender = <span>Trigger list have more than 1 item</span>;
    // } else {
    //     triggerRender = <span>No active triggers</span>;
    // }


    return (
        <Modal title={`Edit field`} confirmLoading={isLoading} open={isOpenEditModal} onOk={handleOk}
               onCancel={handleCancel}>
            <ColumnEditForm form={form} onSubmit={null} handleFormChanges={handleColumnFormChanges}/>
            {/*{triggerRender}*/}
            <ColumnPopupFieldsManager
                isEditing={true}
                tableId={tableId}
                accountMetaColumnId={columnMetaData["id"]}
                columnTypeId={columnTypeId}
                pluginFieldDataList={triggerList[0]}
                onUpdate={handleTriggerFormChanges}
            />
        </Modal>
    );

};

export default EditColumnPopup;

import {useEffect, useState} from "react";
import {useApi } from "../utils/requests";
import {Button, Col, Form, Row, Spin} from "antd";
import {triggerFormGenerator} from "../utils/Forms";

const PluginSettingsView = (props) => {
    const {accountPluginId} = props;
    const {getFieldSchemaSettingsRequest, postFieldSchemaSettingsRequest} = useApi();
    const [fieldSchmaList, setFieldSchemaList] = useState([]);
    const [fieldValueObject, setFieldValueObject] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const gettingData = async () => {
        const pluginFieldData = await getFieldSchemaSettingsRequest(accountPluginId);
        setFieldSchemaList(pluginFieldData["field_schema_list"]);
        setFieldValueObject(pluginFieldData["field_value_object"]);
    }

    const gettingFieldSchema = async (accountPluginId) => {
        setIsLoading(true);
        await gettingData(accountPluginId);
        setIsLoading(false);
    }

    useEffect(() => {
        gettingFieldSchema(accountPluginId);
    }, [props?.accountPluginId]);


    const handleRichEditorFormUpdates = () => {

    }

    const onFinish = async (form) => {
        console.log("form = ", form);
        setIsSaving(true);
        await postFieldSchemaSettingsRequest(accountPluginId, form);
        setIsSaving(false);
    }

    let triggerDataObj = {};
    let formRender = triggerFormGenerator(fieldSchmaList, triggerDataObj, handleRichEditorFormUpdates, null);

    return (<div>
        {isLoading ? <Spin/> : <Row>
            <Col span={10}>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={fieldValueObject}
                >

                    {formRender}

                    <Form.Item style={{textAlign: "right"}}>
                        <Button loading={isSaving} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>}

    </div>)

}

export default PluginSettingsView;
